import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { format, isValid } from 'date-fns';

const dateI18n = {
  // maxDateMessage: 'Data não pode ser maior que a data máxima',
  // minDateMessage: 'Data não pode ser menor que a data mínima',
  // invalidDateMessage: 'Formato da data inválido'
};

const hasError = (props, value) => {
  let { validate, required, disablePast } = props;
  if (typeof validate === 'function') {
    const error = validate(value);
    if (error) {
      return error;
    }
  }
  if (required && !value) {
    return 'required';
  }
  if (disablePast && new Date(value) < new Date()) {
    return 'is_past';
  }
  return false;
};

function InputDateComponent (props) {
  const { onChange, prop, value, withTime, error, setError, ...other } = props;
  const [currentValue, setCurrentValue] = useState(value && !withTime ? `${value} 00:00:00` : value);

  useEffect(() => setCurrentValue(value && !withTime ? `${value} 00:00:00` : value), [value, withTime]);

  const handleChange = useCallback(val => {
    let parsedVal = isValid(val) ? val : null;
    if (!withTime && parsedVal) {
      parsedVal = format(val, 'yyyy-MM-dd');
    }
    if (prop) {
      setCurrentValue(val);
      onChange(prop, parsedVal);
    } else {
      onChange(parsedVal);
    }
  }, [onChange, prop, withTime]);

  const validationError = hasError(props, currentValue);
  useEffect(() => setError && setError(prop, validationError), [prop, setError, validationError]);

  const customProps = {
    name: prop,
    ...other,
    value: currentValue || null,
    onChange: handleChange,
    error: error || !!validationError
  };
  delete customProps.validate;
  delete customProps.fillWidth;

  if (withTime) {
    return (
      <KeyboardDateTimePicker
        format="dd/MM/yyyy HH:mm"
        variant="inline"
        fullWidth
        {...dateI18n}
        {...customProps}
      />
    );
  }
  return (
    <KeyboardDatePicker
      format="dd/MM/yyyy"
      variant="inline"
      fullWidth
      {...dateI18n}
      {...customProps}
    />
  );
}

InputDateComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  prop: PropTypes.string,
  value: PropTypes.any,
  withTime: PropTypes.bool,
  error: PropTypes.bool,
  setError: PropTypes.func,

  validate: PropTypes.func,
  required: PropTypes.bool,
  disablePast: PropTypes.bool
};

export default memo(InputDateComponent);
