import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  breadcrumbs: {
    listStyleType: 'none',
    minHeight: 36,
    margin: 0,
    padding: 0,
    display: 'flex',
    '& > li': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  icon: {
    marginRight: theme.spacing(),
    display: 'block'
  },
  text: {
    marginRight: theme.spacing(),
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1,
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary
    }
  },
  current: {
    marginRight: theme.spacing(),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1
  }
}));