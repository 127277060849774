import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:feedbacks',
  show: 'get:feedback',
  update: 'patch:feedback'
};

export default function useFeedback () {
  const { resource } = useResource('/feedbacks', policies);
  return resource;
}
