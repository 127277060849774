import styled from 'styled-components';

export const Enclose = styled.span`
`;

export const Content = styled.span`
  & > *:last-child > *:last-child {
    display: none;
  }
`;

export const Line = styled.div`
  padding-left: 16px;

  &:hover {
    background-color: rgba(50, 50, 50, .05);
  }
`;

export const Key = styled.span`
  color: mediumPurple;
  padding-right: 8px;
`;

export const Variable = styled.span`
  color: blue;
`;

export const String = styled.span`
  color: seaGreen;
  word-wrap: break-word;
`;

export const Null = styled.span`
  color: silver;
`;

export const Comma = styled.span`
  color: grey;
`;
