import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(13)
  },
  highlight: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary
  },
  keep: {
    whiteSpace: 'nowrap'
  }
}));
