import { byId } from 'helpers/misc';

export const FEEDBACK_STATUS = [
  {
    id: 'pending',
    name: 'Pendente'
  },
  {
    id: 'progress',
    name: 'Em andamento'
  },
  {
    id: 'finished',
    name: 'Resolvido'
  }
];

export const FEEDBACK_TYPES = [
  {
    id: 'units',
    name: 'Unidade'
  }
];

export const FEEDBACK_STATUS_BY_ID = byId(FEEDBACK_STATUS);
export const getFeedbackStatusName = statusId => FEEDBACK_STATUS_BY_ID[statusId];

export const FEEDBACK_TYPES_BY_ID = byId(FEEDBACK_TYPES);
export const getFeedbackTypeName = statusId => FEEDBACK_TYPES_BY_ID[statusId];
