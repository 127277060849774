import { useCallback, useMemo } from 'react';
import useResource from 'hooks/useSequelize';
import { LAST_AUTOCOMPLETE_RESULTS, AUTOCOMPLETE_RESULTS } from 'constant';

const policies = {
  list: 'get:units',
  show: 'get:unit',
  create: 'post:units',
  update: 'patch:unit'
};

export default function useUnit () {
  const { resource, request, setItem, hasAccess } = useResource('/units', policies, { label: 'businessName' });
  const { item: unit, fetchAll } = resource;

  const fetchByName = useCallback(async (name, where) => {
    const params = name
      ? { where: { ...where, businessName: { like: name } }, order: 'businessName', direction: 'ASC', perPage: AUTOCOMPLETE_RESULTS }
      : { where, order: 'createdAt', direction: 'DESC', perPage: LAST_AUTOCOMPLETE_RESULTS };
    return await fetchAll(params);
  }, [fetchAll]);

  const updateAddress = useCallback(async (payload, id = unit?.id) => {
    if (!id) {
      throw new Error('unitId é obrigatório');
    }
    const { data: Address } = await request(`${id}/addresses`, { method: 'patch', payload });
    setItem(prev => ({ ...prev, Address }));
    return Address;
  }, [unit?.id, request, setItem]);

  const customMethods = useMemo(() => {
    const methods = {};
    if (fetchAll) {
      methods.fetchByName = fetchByName;
    }
    if (hasAccess('patch:unit/address')) {
      methods.updateAddress = updateAddress;
    }
    return methods;
  }, [fetchAll, fetchByName, hasAccess, updateAddress]);

  return { ...resource, ...customMethods };
}
