import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { Alert, Button } from 'components';

const ConfirmationModal = ({ open, title, description, type, size, onConfirm, confirmLabel, cancelLabel, onClose, reason }) => {
  const [value, setValue] = useState('');
  const [reasonValue, setReasonValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const invalid = useMemo(() => !!type && type !== value, [type, value]);

  const handleClose = useCallback(
    evt => {
      setValue('');
      setReasonValue('');
      setErrorMessage(null);
      setIsLoading(false);
      onClose && onClose(evt);
    },
    [onClose]
  );

  const handleConfirm = useCallback(async () => {
    setErrorMessage(null);
    setIsLoading(true);
    try {
      await onConfirm(reasonValue);
      handleClose();
    } catch (err) {
      setErrorMessage(err?.message);
    }
    setIsLoading(false);
  }, [handleClose, onConfirm, reasonValue]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={!!size}
      maxWidth={size}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {!!title && <DialogTitle id="dialog-title">{title}</DialogTitle>}
      <DialogContent>
        {!!description && <DialogContentText id="dialog-description">{description}</DialogContentText>}
        {!!type && (
          <TextField
            value={value}
            onChange={e => setValue(e.target.value)}
            autoFocus
            fullWidth
            id="confirm"
            autoComplete="off"
            disabled={isLoading}
            placeholder={`para confirmar digite '${type}' sem aspas`}
          />
        )}
        {!!reason && (
          <TextField
            value={reasonValue}
            onChange={e => setReasonValue(e.target.value)}
            autoFocus={!type}
            fullWidth
            id="reason"
            multiline
            rows={3}
            disabled={isLoading}
            placeholder="Informe um motivo"
          />
        )}
        {!!errorMessage && (
          <Alert severity="error" mt={2}>{errorMessage}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading} color="default">
          {cancelLabel}
        </Button>
        {!!onConfirm && (
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="secondary"
            autoFocus={!type}
            disabled={invalid}
            loading={isLoading}
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  description: PropTypes.node,
  type: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  reason: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
};

ConfirmationModal.defaultProps = {
  confirmLabel: 'Confirmar',
  cancelLabel: 'Cancelar',
  size: 'xs'
};

export default ConfirmationModal;
