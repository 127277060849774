import { lazy } from 'react';

const root = '/ge';

export const paymentSellerList = root;
export const paymentSellerCreate = `${root}/adicionar`;
export const paymentSellerUpdate = (id = ':paymentSellerId') => `${root}/${id}/editar`;
export const paymentSellerUpdateGateways = (id = ':paymentSellerId') => `${root}/${id}/gateways`;
export const paymentSellerUpdatePartners = (id = ':paymentSellerId') => `${root}/${id}/parceiros`;
export const paymentSellerUpdateSellers = (id = ':paymentSellerId') => `${root}/${id}/vendedores`;
export const paymentSellerUpdateClients = (id = ':paymentSellerId') => `${root}/${id}/clientes`;
export const paymentSellerUpdateAccess = (id = ':paymentSellerId') => `${root}/${id}/acessos`;
export const paymentSellerUpdatePlan = (id = ':paymentSellerId') => `${root}/${id}/plano`;
export const paymentSellerUpdateLogs = (id = ':paymentSellerId') => `${root}/${id}/logs`;

export function getPaymentSellerUpdateRoutes (paymentSellerId) {
  return [
    {
      key: 'info',
      path: paymentSellerUpdate(paymentSellerId),
      label: 'Informações',
      component: lazy(() => import('./update/Information'))
    },
    {
      key: 'gateways',
      path: paymentSellerUpdateGateways(paymentSellerId),
      policy: 'get:gateways',
      label: 'Gateways',
      component: lazy(() => import('./update/Gateways'))
    },
    {
      key: 'partners',
      path: paymentSellerUpdatePartners(paymentSellerId),
      policy: 'get:payment_seller/partnerships',
      label: 'Parceiros',
      component: lazy(() => import('./update/Partners'))
    },
    {
      key: 'sellers',
      path: paymentSellerUpdateSellers(paymentSellerId),
      label: 'Vendedores',
      component: lazy(() => import('./update/Sellers.js'))
    },
    {
      key: 'customers',
      path: paymentSellerUpdateClients(paymentSellerId),
      policy: 'get:payment_seller/customers',
      label: 'Clientes',
      component: lazy(() => import('./update/Customers'))
    },
    {
      key: 'access',
      path: paymentSellerUpdateAccess(paymentSellerId),
      policy: 'get:accesses',
      label: 'Acessos',
      component: lazy(() => import('./update/Access'))
    },
    {
      key: 'plan',
      path: paymentSellerUpdatePlan(paymentSellerId),
      policy: 'get:payment_seller_partners',
      label: 'Planos',
      component: lazy(() => import('./update/Plan'))
    },
    {
      key: 'logs',
      path: paymentSellerUpdateLogs(paymentSellerId),
      policy: 'get:gateway_logs',
      label: 'Logs',
      component: lazy(() => import('./update/Logs'))
    }
  ];
};

const routes = [
  {
    key: 'paymentSellerList',
    path: paymentSellerList,
    policy: 'get:payment_sellers',
    label: 'Grupos econômicos',
    component: lazy(() => import('./list'))
  },
  {
    key: 'paymentSellerCreate',
    path: paymentSellerCreate,
    policy: 'post:payment_sellers',
    label: 'Adicionar',
    component: lazy(() => import('./create'))
  },
  {
    key: 'paymentSellerUpdate',
    path: getPaymentSellerUpdateRoutes().map(r => r.path),
    policy: 'get:payment_seller',
    label: 'Detalhe',
    component: lazy(() => import('./update'))
  }
];

export default routes;