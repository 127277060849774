import { lazy } from 'react';

const root = '/documentos';

export const documentList = root;
export const documentCreate = `${root}/adicionar`;
export const documentUpdate = (id = ':documentId') => `${root}/${id}/editar`;

const routes = [
  {
    key: 'documentList',
    path: documentList,
    policy: 'get:documents',
    label: 'Documentos',
    component: lazy(() => import('./list'))
  },
  {
    key: 'documentCreate',
    path: documentCreate,
    policy: 'post:documents',
    label: 'Adicionar',
    component: lazy(() => import('./create'))
  },
  {
    key: 'documentUpdate',
    path: documentUpdate(),
    policy: 'get:document',
    label: 'Detalhe',
    component: lazy(() => import('./update'))
  }
];

export default routes;