import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { useSnackbar } from 'notistack';
import { Queue as AddIcon } from '@material-ui/icons';
import { IconButton } from 'components';
import TreeItem from './Item';
import { setDataOnArray } from 'helpers';
import get from 'lodash/get';
import useStyles from './styles';

function TreeViewDrop ({ parentId, ...props }) {
  const { recursiveProp, type, getData, parentIds, onClick, onCreate, onUpdate, onRemove, parseItems } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: type,
    drop (item, monitor) {
      if (monitor.didDrop() || !monitor.isOver({ shallow: true })) {
        return;
      }
      onUpdate({ [recursiveProp]: parentId }, item.id)
        .then(response => setItems(prev => setDataOnArray(prev, response)));
      return { moved: true };
    },
    canDrop (item) {
      return get(item, recursiveProp) !== parentId && !parentIds.includes(item.id);
    },
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop()
    })
  });
  const classes = useStyles({ isOver, canDrop });

  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getData(parentId);
      setItems(response);
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar, getData, parentId]);

  const removeItem = useCallback(itemId => (
    setItems(prev => prev.filter(item => item.id !== itemId))
  ), []);

  const handleRemove = useCallback(async itemId => {
    await onRemove(itemId);
    removeItem(itemId);
  }, [onRemove, removeItem]);

  const handleUpdate = useCallback((evt, item) => (
    onClick(evt, item, async payload => {
      const response = await onUpdate(payload, item.id);
      setItems(prev => setDataOnArray(prev, response));
    })
  ), [onClick, onUpdate]);

  const handleCreate = useCallback(evt => (
    onClick(evt, {}, async payload => {
      const response = await onCreate({ ...payload, [recursiveProp]: parentId });
      setItems(prev => setDataOnArray(prev, response));
    })
  ), [onClick, onCreate, parentId, recursiveProp]);

  useEffect(() => fetchItems(), [fetchItems]);

  const parsedItems = useMemo(() => parseItems ? parseItems(items) : items, [items, parseItems]);

  const itemProps = { ...props };

  if (onUpdate) {
    itemProps.onUpdateItem = handleUpdate;
    itemProps.onMoveOut = removeItem;
  }
  if (onRemove) {
    itemProps.onRemoveItem = handleRemove;
  }

  return (
    <>
      <div className={classes.drop} ref={drop}>
        {parsedItems?.map(item => (
          <TreeItem {...itemProps} key={item.id} item={item} />
        ))}

        {!!onCreate && (
          <div className={classes.li}>
            <div className={classes.item}>
              <IconButton
                size="small"
                onClick={handleCreate}
                label="Adicionar"
                loading={isLoading}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
            <div className={classes.ul} />
          </div>
        )}
      </div>
    </>
  );
}

TreeViewDrop.propTypes = {
  recursiveProp: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  getData: PropTypes.func.isRequired,
  parentIds: PropTypes.array.isRequired,
  parentId: PropTypes.any,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
  parseItems: PropTypes.func
};

TreeViewDrop.defaultProps = {
  type: 'TREE_ITEM',
  parentIds: [],
  parentId: null
};

export default memo(TreeViewDrop);

