import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:plan_units',
  create: 'post:plan_units',
  update: 'patch:plan_unit',
  remove: 'delete:plan_unit'
};

export default function usePlanUnit () {
  const { resource } = useResource('planUnits', policies, { append: true });

  return resource;
}
