import { lazy } from 'react';

const root = '/feedbacks';

export const feedbackList = root;
export const feedbackUpdate = (id = ':feedbackId') => `${root}/${id}`;

const routes = [
  {
    key: 'feedbackList',
    path: feedbackList,
    label: 'Feedbacks',
    policy: 'get:feedbacks',
    component: lazy(() => import('./list'))
  },
  {
    key: 'feedbackUpdate',
    path: feedbackUpdate(),
    label: 'Detalhe',
    policy: 'get:feedback',
    component: lazy(() => import('./update'))
  }
];

export default routes;