import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:payment_seller_partners',
  create: 'post:payment_seller_partners',
  update: 'patch:payment_seller_partner',
  remove: 'delete:payment_seller_partner'
};

export default function usePaymentSellerPartner () {
  const { resource } = useResource('/paymentSellerPartners', policies);

  return resource;
}
