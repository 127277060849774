import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Slide, useScrollTrigger }  from '@material-ui/core';
import { ExitToApp as SignOutIcon, Menu as MenuIcon, Work as RoleIcon } from '@material-ui/icons';
import { IconButton, Flex } from 'components';
import { signIn } from 'pages/routes';
import { NAME } from 'constant';
import { useAuth, useAuthAccess, useWindow } from 'context';
import useStyles from './styles';
import logo from 'assets/logo-nema_simbolo-branco.png';

function AppBarComponent ({ onClick, ...other }) {
  const classes = useStyles();
  const { currentUser, signOut, loading: { signOut: isLoading } } = useAuth();
  const { currentAccess, setAccess } = useAuthAccess();
  const { pathname } = useLocation();
  const trigger = useScrollTrigger();
  const { addDisplacement } = useWindow();

  useEffect(() => addDisplacement(64), [addDisplacement]);

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar {...other}>
          <Toolbar>
            {!!onClick && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={onClick}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img src={logo} alt={NAME} className={classes.logo} />
            <div className={classes.grow} />
            {currentUser ? (
              <>
                <Flex align="flex-end" column mr={2}>
                  <Typography className={classes.userInfo}>
                    {currentUser.Profile?.name}
                  </Typography>
                  <Typography className={classes.userRole}>
                    {currentAccess?.Role?.name}
                  </Typography>
                </Flex>
                <IconButton
                  onClick={() => setAccess()}
                  label="Trocar Papel"
                  placement="bottom"
                  color="inherit"
                >
                  <RoleIcon />
                </IconButton>
                <IconButton
                  onClick={() => signOut()}
                  loading={isLoading}
                  label="Sair"
                  placement="bottom"
                  color="inherit"
                  loaderColor="secondary"
                >
                  <SignOutIcon />
                </IconButton>
              </>
            ) : pathname !== signIn && (
              <Button color="inherit" component={Link} to={signIn}>Login</Button>
            )}
          </Toolbar>
        </AppBar>
      </Slide>
      <Toolbar />
    </>
  );
}

AppBarComponent.propTypes = {
  onClick: PropTypes.func
};

export default memo(AppBarComponent);
