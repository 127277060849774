import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:payment_seller_fees',
  create: 'post:payment_seller_fees',
  update: 'patch:payment_seller_fee',
  remove: 'delete:payment_seller_fee'
};

export default function usePaymentSellerFee () {
  const { resource } = useResource('/paymentSellerFees', policies);

  return resource;
}
