import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, TablePagination, IconButton } from '@material-ui/core';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon
} from '@material-ui/icons';
import useStyles from './styles';

function PaginationActions ({ lastPage, firstPage, page, disabled, onPageChange }) {
  const isLastPage = page >= lastPage;
  const isFirstPage = page <= firstPage;
  const classes = useStyles();

  return (
    <Box displayPrint="none" className={classes.paginationActions}>
      <IconButton
        onClick={() => onPageChange(firstPage)}
        disabled={disabled || isFirstPage}
        aria-label="Primeira página"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={() => onPageChange(page - 1)}
        disabled={disabled || isFirstPage}
        aria-label="Página anterior"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={() => onPageChange(page + 1)}
        disabled={disabled || isLastPage}
        aria-label="Próxima página"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={() => onPageChange(lastPage)}
        disabled={disabled || isLastPage}
        aria-label="Última página"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

PaginationActions.propTypes = {
  firstPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  disabled: PropTypes.bool
};

function ListViewPagination ({
  count,
  page,
  options,
  rowsPerPage,
  disabled,
  onChangePage,
  onChangePerPage,
  firstPage
}) {
  if (!count) {
    return null;
  }
  const lastPage = Math.max(firstPage, Math.ceil(count / rowsPerPage) - 1);
  if (page > lastPage) {
    return null;
  }

  return (
    <TablePagination
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangePerPage}
      rowsPerPageOptions={options}
      ActionsComponent={props => (
        <PaginationActions
          {...props}
          lastPage={lastPage}
          firstPage={firstPage}
          disabled={disabled}
        />
      )}
    />
  );
}

ListViewPagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  firstPage: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.number),
  count: PropTypes.number,
  disabled: PropTypes.bool
};

ListViewPagination.defaultProps = {
  firstPage: 0,
  options: [5, 15, 30]
};

export default memo(ListViewPagination);
