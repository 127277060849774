import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.info.main
  },
  text: {
    marginLeft: theme.spacing(),
    color: theme.palette.info.dark
  }
}));
