import useResource from 'hooks/useSequelize';

const policies = {
  create: 'post:partner_product_values',
  update: 'patch:partner_product_value',
  remove: 'delete:partner_product_value'
};

export default function usePartnerProductValue () {
  const { resource } = useResource('/partnerProductValues', policies);

  return resource;
}
