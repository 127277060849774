import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2)
  },
  content: {
    padding: 0
  }
}));
