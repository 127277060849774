import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Slide, IconButton, Typography } from '@material-ui/core';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import useStyles from './styles';

const DATA = [];
const ON_OPEN = fn => {
  if (typeof fn === 'function') {
    DATA.forEach((fn, index) => fn(DATA.length - index));
    DATA.push(fn);
    return () => {
      DATA.pop();
      DATA.forEach((fn, index) => fn(DATA.length - index - 1));
    };
  }
};

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function SideNavigationComponent ({ open, children, onClose, title, ...other }) {
  const [index, setIndex] = useState(0);
  const classes = useStyles({ index });

  useEffect(() => open && ON_OPEN(setIndex), [open]);

  return (
    <Dialog
      scroll="paper"
      {...other}
      open={open}
      TransitionComponent={Transition}
      fullScreen
      className={classes.root}
      onClose={onClose}
    >
      <DialogTitle className={classes.header} disableTypography>
        <IconButton onClick={onClose}>
          <BackIcon />
        </IconButton>
        <Typography className={classes.title} component="h2">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

SideNavigationComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string
};
