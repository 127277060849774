import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const StyledTypography = styled(Typography)(compose(spacing));

export default function TypographyComponent (props) {
  return <StyledTypography {...props} />;
};
