import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Enclose, Content, Line, Key, Variable, String, Null, Comma } from './styles';

const wrap = (value, char = '"') => char + value + char;

const isJsonString = string => {
  const isObject = /^{.*}$/.test(string);
  if (isObject) {
    return true;
  }
  const isArray = /^\[.*\]$/.test(string);
  return isArray;
};

function DisplayJsonComponent ({ value, parser }) {
  if (!value) {
    return null;
  }

  let json = value;
  if (typeof value !== 'object') {
    try {
      json = JSON.parse(value);
    } catch (err) {
      return <Typography>{value}</Typography>;
    }
  }

  const isArray = Array.isArray(json);
  const enclose = isArray ? ['[', ']'] : ['{', '}'];

  const contents = [];

  for (const key in json) {
    let val = json[key];
    if (typeof parser?.[key] === 'function') {
      val = parser[key](val);
    }
    let _value;
    switch (typeof val) {
      case 'boolean':
        _value = <Variable>{val ? 'true' : 'false'}</Variable>;
        break;
      case 'number':
        _value = <Variable>{val}</Variable>;
        break;
      case 'object':
        _value = !val
          ? <Null>null</Null>
          : <DisplayJsonComponent value={val} parser={parser} />;
        break;
      default:
        _value = !isJsonString(val)
          ? <String>{wrap(val)}</String>
          : <DisplayJsonComponent value={val} parser={parser} />;
        break;
    }
    contents.push(
      <Line key={key}>
        {!isArray && <Key>{wrap(key)}:</Key>}
        {_value}
        <Comma>{','}</Comma>
      </Line>
    );
  }

  return (
    <Typography component="span">
      <Enclose>{enclose[0]}</Enclose>
      <Content>
        {contents}
      </Content>
      <Enclose>{enclose[1]}</Enclose>
    </Typography>
  );
}

DisplayJsonComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  parser: PropTypes.object
};

export default memo(DisplayJsonComponent);
