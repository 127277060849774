import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary } from '@material-ui/core';

export const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar
  }
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    padding: 0,
    display: 'initial'
  }
})(MuiAccordionDetails);

export default makeStyles(theme => ({
  group: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  groupTitle: {
    flex: 1,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    justifyContent: 'space-between'
  },
  item: {
    '&:last-child': {
      marginBottom: theme.spacing()
    },
    '& + &': {
      borderTop: `1px solid ${theme.palette.divider}`
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  data: {
    fontSize: theme.typography.pxToRem(14)
  },
  empty: {
    color: theme.palette.text.secondary
  }
}));
