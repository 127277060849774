import { useMemo } from 'react';
import useResource from 'hooks/useSequelize';
import sortBy from 'lodash/sortBy';

const defaultParams = { order: 'name', direction: 'asc' };
const policies = {
  list: 'get:roles',
  show: 'get:role',
  create: 'post:roles',
  update: 'patch:role',
  remove: 'delete:role'
};

export default function useRole () {
  const { resource } = useResource('/roles', policies, { defaultParams });
  const { list } = resource;

  const sorted = useMemo(() => list && sortBy(list, 'applicationId'), [list]);

  return { ...resource, sorted };
}
