import React, { memo, Children, useCallback, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Button } from '@material-ui/core';
import { Flex, Select } from 'components';
import useStyles from './styles';
import useForm from 'hooks/useForm';

function ListViewFilters ({ data, onFilter, children, disabled }) {
  const onClear = useCallback(() => onFilter(), [onFilter]);

  const {
    handleSubmit,
    handleClear,
    childrenWithValues,
    hasError
  } = useForm({ data, onSubmit: onFilter, onClear, children, disabled });

  const classes = useStyles();

  if (!children?.length) {
    return null;
  }

  return (
    <Paper component="form" className={classes.filters} onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center">
        {Children.map(childrenWithValues, child => {
          if (!child) {
            return null;
          }
          const { xl = 2, lg = 3, md = 4, sm = 6, xs = 12, emptyLabel = 'Todos' } = child.props;
          const content = child.type === Select ? cloneElement(child, { emptyAsUndefined: true, emptyLabel }) : child;
          return (
            <Grid item xl={xl} lg={lg} md={md} sm={sm} xs={xs} className={classes.filter}>
              {content}
            </Grid>
          );
        })}
        <Grid item>
          <Flex spacing={2}>
            <Button type="submit" variant="contained" color="secondary" disabled={disabled || hasError}>
              Pesquisar
            </Button>
            <Button variant="outlined" disabled={disabled} onClick={handleClear}>
              Limpar
            </Button>
          </Flex>
        </Grid>
      </Grid>
    </Paper>
  );
}

ListViewFilters.propTypes = {
  data: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool
};

ListViewFilters.defaultProps = {
  data: {}
};

export default memo(ListViewFilters);
