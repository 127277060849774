import { useCallback, useMemo } from 'react';
import useResource from 'hooks/useSequelize';
import Api from 'providers/api';

const policies = {
  list: 'get:accesses',
  update: 'patch:access',
  remove: 'delete:access'
};

export default function useAccess () {
  const { resource, handleChangeItem, hasAccess } = useResource('/accesses', policies);

  const createItem = useCallback(async ({
    userId,
    User,
    roleId,
    Role,
    paymentSellerId,
    PaymentSeller,
    unitId,
    Unit,
    ...rest
  }) => {
    const _userId = userId ?? User?.id;
    if (!_userId) {
      throw new Error('userId é obrigatório');
    }
    const _roleId = roleId ?? Role?.id;
    if (!_roleId) {
      throw new Error('roleId é obrigatório');
    }
    const _paymentSellerId = paymentSellerId ?? PaymentSeller?.id;
    const _unitId = unitId ?? Unit?.id;
    const { data } = await Api.post(`users/${_userId}/accesses`, {
      roleId: _roleId,
      paymentSellerId: _paymentSellerId,
      unitId: _unitId,
      ...rest
    });
    const access = { ...data, User, Role, PaymentSeller, Unit };
    handleChangeItem(access);
    return access;
  }, [handleChangeItem]);

  const customMethods = useMemo(() => {
    if (hasAccess('post:user/accesses')) {
      return { createItem };
    }
  }, [createItem, hasAccess]);

  return {
    ...resource,
    ...customMethods
  };
}
