import { lazy } from 'react';

const root = '/planos/parceiros';

export const partnerList = root;
export const partnerUpdate = (id = ':partnerId') => `${root}/${id}/editar`;
export const partnerUpdateDocuments = (id = ':partnerId') => `${root}/${id}/documentos`;

export function getPartnerUpdateRoutes (partnerId) {
  return [
    {
      key: 'properties',
      path: partnerUpdate(partnerId),
      label: 'Propriedades',
      component: lazy(() => import('./update/Properties'))
    },
    {
      key: 'documents',
      path: partnerUpdateDocuments(partnerId),
      label: 'Documentos',
      component: lazy(() => import('./update/Documents'))
    }
  ];
};

const routes = [
  {
    key: 'partnerList',
    path: partnerList,
    label: 'Parceiros',
    policy: 'get:partners',
    component: lazy(() => import('./list'))
  },
  {
    key: 'partnerUpdate',
    path: getPartnerUpdateRoutes().map(r => r.path),
    label: 'Detalhe',
    policy: 'get:partner',
    component: lazy(() => import('./update'))
  }
];

export default routes;