import React, { useState, useEffect, useCallback, useMemo, Children, memo } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Alert, FabButton, FabContainer, Input, Select, DateInput, Autocomplete, AsyncAutocomplete } from 'components';
import { ListBody, ListButtons, ListButton, ListData, ListHead, ListFilters, ListPagination } from '.';
import useStyles from './styles';
import { useQueryParams } from 'hooks';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';
import get from 'lodash/get';

const Styled = styled.div(compose(spacing));

function ListView ({
  children,
  perPage,
  getData,
  data,
  where,
  loading,
  order,
  direction,
  createUrl,
  createLabel,
  getRowStyle,
  urlAsParams,
  ...others
}) {
  const [params, setParams] = useQueryParams({
    perPage,
    order,
    direction,
    page: 0,
    where
  }, { disabled: !urlAsParams });
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const classes = useStyles();
  const isLoading = loading || isLoadingInternal;

  const [columns, filters, required] = useMemo(() => {
    const array = Children.toArray(children);
    const _columns = array.filter(c => [ListData, ListButtons].includes(c.type));
    const _filters = array.filter(c => [Input, Select, DateInput, Autocomplete, AsyncAutocomplete].includes(c.type));
    const buttons = array.filter(c => c.type === ListButton);
    if (buttons.length) {
      _columns.push(<ListButtons key="buttons">{buttons}</ListButtons>);
    }
    const _required = _filters.reduce((result, child) => {
      const { required, prop } = child.props;
      if (required && prop) {
        result.push(prop);
      }
      return result;
    }, []).join('|');

    return [_columns, _filters, _required];
  }, [children]);

  const isRequestValid = useMemo(() => {
    if (!required) {
      return true;
    }
    let valid = true;
    for (const key of required.split('|')) {
      if (!get(params.where, key)) {
        valid = false;
        break;
      }
    }
    return valid;
  }, [params.where, required]);

  const handleChangePage = useCallback(page => {
    setParams(prev => ({ ...prev, page }));
  }, [setParams]);

  const handleChangeWhere = useCallback(next => {
    setParams(prev => ({ ...prev, page: 0, where: next }));
  }, [setParams]);

  const handleChangePerPage = useCallback(evt => {
    setParams(prev => ({ ...prev, perPage: evt.target.value }));
  }, [setParams]);

  const handleSort = useCallback(property => {
    setParams(prev => {
      if (prev.order === property) {
        if (prev.direction === 'desc') {
          return { ...prev, order: null };
        }
        return { ...prev, direction: 'desc' };
      }
      return { ...prev, order: property, direction: 'asc' };
    });
  }, [setParams]);

  const fetchData = useCallback(async () => {
    if (!isRequestValid || !getData) {
      return;
    }
    setIsLoadingInternal(true);
    setErrorMessage(null);
    try {
      await getData(params);
    } catch (err) {
      setErrorMessage(err?.message || 'Ocorreu um erro ao requisitar os dados solicitados!');
    }
    setIsLoadingInternal(false);
  }, [getData, isRequestValid, params]);

  useEffect(fetchData, [fetchData]);
  useEffect(() => {
    if (params.page && params.page >= data?.meta?.totalPages) {
      setParams(prev => ({ ...prev, page: 0 }), true);
    }
  }, [params.page, data, setParams]);

  return (
    <Styled {...others}>
      <ListFilters
        data={params.where}
        onFilter={handleChangeWhere}
        disabled={isLoading}
      >
        {filters}
      </ListFilters>
      <Table className={classes.table} stickyHeader>
        <ListHead onRequestSort={handleSort} order={params.order} direction={params.direction}>
          {columns}
        </ListHead>
        <TableBody>
          <ListBody
            data={data?.data}
            loading={isLoading}
            where={params.where}
            getRowStyle={getRowStyle}
          >
            {columns}
          </ListBody>
        </TableBody>
      </Table>

      {!!errorMessage && (
        <Alert severity="error" my={2}>
          {errorMessage}
        </Alert>
      )}

      {!isRequestValid && !data && (
        <Alert severity="warning" my={2}>
          Preencha os filtros obrigatórios para realizar a pesquisa
        </Alert>
      )}

      <ListPagination
        count={data?.meta?.totalCount}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        rowsPerPage={params.perPage}
        page={params.page}
        disabled={isLoading}
      />

      {!!createUrl && (
        <FabContainer>
          <FabButton to={createUrl} color="primary" label={createLabel}>
            <AddIcon />
          </FabButton>
        </FabContainer>
      )}
    </Styled>
  );
}

ListView.propTypes = {
  children: PropTypes.node.isRequired,
  perPage: PropTypes.number.isRequired,
  getData: PropTypes.func,
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any.isRequired
      })
    ),
    meta: PropTypes.shape({
      totalCount: PropTypes.number,
      totalPages: PropTypes.number
    })
  }),
  where: PropTypes.object,
  loading: PropTypes.bool,
  order: PropTypes.string,
  direction: PropTypes.oneOf(['asc', 'desc']),
  createUrl: PropTypes.string,
  createLabel: PropTypes.string,
  getRowStyle: PropTypes.func,
  urlAsParams: PropTypes.bool
};

ListView.defaultProps = {
  perPage: 15,
  order: 'id',
  direction: 'desc',
  createLabel: 'Adicionar'
};

export default memo(ListView);
