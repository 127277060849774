import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { TableCell, TableRow } from '.';
import { times } from 'helpers';

function TableLoaderComponent ({ data, count }) {
  return times(count, 0, rowIndex => (
    <TableRow key={rowIndex}>
      {data?.map((item, colIndex) => {
        let content = typeof item === 'number' ? <Skeleton width={Math.abs(item)} /> : item;
        return (
          <TableCell key={colIndex} align={item < 0 ? 'right' : 'left'}>
            {content}
          </TableCell>
        );
      })}
    </TableRow>
  ));
}

TableLoaderComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.node).isRequired,
  count: PropTypes.number.isRequired
};

TableLoaderComponent.defaultProps = {
  count: 1
};

export default memo(TableLoaderComponent);
