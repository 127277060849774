import React, { useState, useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { useDrag } from 'react-dnd';
import {
  Edit as EditIcon,
  AddBox as OpenIcon,
  IndeterminateCheckBox as CloseIcon
} from '@material-ui/icons';
import { RemoveButton, IconButton } from 'components';
import TreeDrop from './Drop';
import { getProp } from 'helpers';
import useStyles from './styles';

function TreeItem ({ item, onUpdateItem, onRemoveItem, onMoveOut, parentIds, ...props }) {
  const { type, renderItem, removeLabel } = props;
  const classes = useStyles();
  const [open, setOpen] = useState();
  const Icon = open ? CloseIcon : OpenIcon;

  const nextParentIds = useMemo(() => parentIds.concat(item.id), [item.id, parentIds]);

  const handleClick = useCallback(() => setOpen(prev => !prev), []);
  const handleRemove = useCallback(() => onRemoveItem(item.id), [item.id, onRemoveItem]);
  const handleUpdate = useCallback(evt => onUpdateItem(evt, item), [item, onUpdateItem]);

  const [, drag] = useDrag({
    type,
    item,
    canDrag () {
      return !!onMoveOut;
    },
    end (_, monitor) {
      const data = monitor.getDropResult();
      if (data?.moved) {
        onMoveOut(item.id);
      }
    }
  });

  return (
    <div className={classes.li}>
      <div className={classes.item}>
        <div className={classes.button} onClick={handleClick}>
          <Icon className={classes.icon} />
          <div ref={drag} className={classNames(!!onMoveOut && classes.moveable)}>
            {renderItem(item)}
          </div>
        </div>
        <div className={classes.menu}>
          {!!onUpdateItem && (
            <IconButton size="small" onClick={handleUpdate} label="Editar">
              <EditIcon fontSize="small" />
            </IconButton>
          )}
          {!!onRemoveItem && (
            <RemoveButton
              onRemove={handleRemove}
              name={getProp(item, removeLabel)}
              size="small"
            />
          )}
        </div>
      </div>

      <div className={classNames(classes.ul, !open && classes.hide)}>
        {open !== undefined && (
          <TreeDrop {...props} parentId={item.id} parentIds={nextParentIds} />
        )}
      </div>
    </div>
  );
}

TreeItem.propTypes = {
  recursiveProp: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  renderItem: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.any.isRequired
  }).isRequired,
  parentIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  onUpdateItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onMoveOut: PropTypes.func,
  removeLabel: PropTypes.string
};

TreeItem.defaultProps = {
  type: 'TREE_ITEM',
  removeLabel: 'name'
};

export default memo(TreeItem);
