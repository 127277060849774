import { useCallback, useState, useMemo } from 'react';
import Api from 'providers/api';
import { useSnackbar } from 'notistack';
import { useAuthAccess } from 'context';

export default function useContractDocument () {
  const { hasAccess } = useAuthAccess();
  const [loading, setLoading] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const generate = useCallback(async contractDocumentId => {
    if (!contractDocumentId) {
      throw new Error('contractDocumentId é obrigatório');
    }
    setLoading(prev => prev.concat(contractDocumentId));
    setErrorMessage(null);
    try {
      return await Api.post(`/contractDocuments/${contractDocumentId}/generate`);
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
      throw err;
    } finally {
      setLoading(prev => prev.filter(id => id !== contractDocumentId));
    }
  }, [enqueueSnackbar]);

  const customMethods = useMemo(() => {
    const methods = {};
    if (hasAccess('post:contract_document/generate')) {
      methods.generate = generate;
    }
    return methods;
  }, [hasAccess, generate]);

  return {
    isLoading: !!loading.length,
    loading,
    errorMessage,
    ...customMethods
  };
}
