import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

function GrowComponent ({ classes, children }) {
  return (
    <div className={classes.root}>
      {children}
    </div>
  );
}

export default withStyles({
  root: {
    flex: 1
  }
})(GrowComponent);

GrowComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};
