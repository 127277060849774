import get from 'lodash/get';

export function rand (max, min = 0) {
  return parseInt(Math.random() * (max - min)) + min;
}

export function getOnlyNumbers (digits) {
  return typeof digits === 'string' && digits && digits.replace(/\D/g, '');
}

export function times (len, start = 0, parse) {
  const array = [];
  for (let i = start; i < Number(len) + start; i++) {
    array.push(typeof parse === 'function' ? parse(i) : parse ?? i);
  }
  return array;
}

export function isParent (refNode, otherNode) {
  if (!otherNode || !refNode) {
    return false;
  }
  var parent = otherNode?.parentNode;
  do {
    if (refNode == parent) {
      return true;
    } else {
      parent = parent?.parentNode;
    }
  } while (parent);
  return false;
}

export function byId (data, label = 'name', key = 'id') {
  if (!Array.isArray(data)) {
    return {};
  }
  return data.reduce((r, v) => {
    r[v[key]] = label ? get(v, label) : v;
    return r;
  }, {});
}

export function nameById (data, label, key, fallback) {
  const namesById = byId(data, label, key);
  return id => namesById[id] ?? fallback;
}

export function getProp (object, prop, fallback) {
  if (!prop) {
    return object;
  }
  if (typeof object !== 'object') {
    return fallback ?? object;
  }
  if (typeof prop === 'function') {
    return prop(object);
  }
  return get(object, prop);
}

export function setDataOnArray (arr, data, { id = 'id', append = true } = {}) {
  if (Array.isArray(arr)) {
    const index = arr.findIndex(item => item[id] === data[id]);
    if (index >= 0) {
      const next = [...arr];
      next[index] = { ...arr[index], ...data };
      return next;
    }
    return append ? [data, ...arr] : arr.concat(data);
  }
  return arr;
}

export function addDataToArray (arr, data, { id = 'id', append = false } = {}) {
  if (Array.isArray(arr)) {
    const index = arr.findIndex(item => item[id] === data[id]);
    if (index >= 0) {
      arr[index] = { ...arr[index], ...data };
    } else {
      if (append) {
        arr.unshift(data);
      } else {
        arr.push(data);
      }
    }
  }
  return arr;
}
