import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:user_terms'
};

export default function useUserTerm () {
  const { resource } = useResource('/userTerms', policies);

  return resource;
}
