import React from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import { compose, spacing, palette, positions } from '@material-ui/system';

const StyledPaper = styled(Paper)(compose(spacing, palette, positions));

export default function PaperComponent (props) {
  return <StyledPaper {...props} />;
};
