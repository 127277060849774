import { makeStyles } from '@material-ui/core/styles';

const OVERLAP_WIDTH = 80;

export default makeStyles(theme => ({
  root: {
    marginLeft: ({ index }) => -index * OVERLAP_WIDTH,
    [theme.breakpoints.up('md')]: {
      left: '125px !important'
    },
    [theme.breakpoints.up('lg')]: {
      left: '250px !important'
    },
    [theme.breakpoints.up('xl')]: {
      left: '500px !important'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    paddingLeft: theme.spacing(),
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightMedium
  },
  content: {
    paddingTop: 0,
    '& img:only-child': {
      maxWidth: '100%',
      display: 'block'
    },
    '& iframe:only-child': {
      display: 'block',
      width: '100%',
      border: 0,
      height: 'calc(100vh - 96px)'
    }
  }
}));