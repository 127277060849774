import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from 'components';
import { getProp } from 'helpers';
import debounce from 'lodash/debounce';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const hasError = (props, value) => {
  const { validate, required } = props;
  if (typeof validate === 'function') {
    const error = validate(value);
    if (error) {
      return error;
    }
  }
  if (required && !value) {
    return 'required';
  }
  return false;
};

function AsyncAutocompleteComponent (props) {
  const {
    onChange,
    onSearch,
    value,
    prop,
    disabled,
    required,
    getValue,
    getLabel,
    getOption = getLabel,
    setError,
    error,
    groupBy,
    data,
    delay,
    fetchOnStart,
    ...other
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState();
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [timestamp, setTimestamp] = useState();
  useEffect(() => setTimestamp(new Date().getTime()), [data]);

  useEffect(() => {
    const val = value && options?.find(opt => getValue ? getProp(opt, getValue) === value : opt?.id === value?.id);
    // somente altera se o id do option for diferente
    setCurrentValue(prev => !value ? value : !val || prev?.id === val?.id ? prev : val);
  }, [options, getValue, value, data]);

  const validationError = hasError(props, currentValue);
  useEffect(() => setError && setError(prop, validationError), [prop, setError, validationError]);

  delete other.validate;

  const handleChangeValue = useCallback((_, val) => {
    const current = getProp(val, getValue);
    if (prop) {
      setCurrentValue(val);
      onChange(prop, current);
    } else {
      onChange(current);
    }
  }, [onChange, prop, getValue]);

  const fetchOptions = useCallback(async (query, where) => {
    setIsLoading(true);
    try {
      const response = await onSearch(query, where);
      return setOptions(response);
    } catch (err) {
      setSearchError(true);
    } finally {
      setIsLoading(false);
    }
  }, [onSearch]);

  const fetchOptionsWithQuery = useMemo(() => debounce(fetchOptions, delay), [delay, fetchOptions]);

  const handleChangeType = useCallback(val => {
    setOptions(null);
    fetchOptionsWithQuery(val);
  }, [fetchOptionsWithQuery]);

  const handleChangeInput = useCallback((_, val) => {
    setInputValue(val);
    if (!val) {
      fetchOptionsWithQuery(val);
    }
  }, [fetchOptionsWithQuery]);

  useEffect(() => {
    if (options === undefined && (fetchOnStart || value)) {
      fetchOptions(null, { id: value });
    }
  }, [fetchOnStart, fetchOptions, options, value]);

  const optionsWithValue = useMemo(() => {
    if (!currentValue || !options || options.find(item => item.id === currentValue.id)) {
      return options ?? [];
    }
    return [currentValue].concat(options);
  }, [currentValue, options]);

  const renderOption = useCallback((option, { inputValue }) => {
    const optionValue = getProp(option, getOption);
    const matches = match(optionValue, inputValue, { insideWords: true });
    const parts = parse(optionValue, matches);

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  }, [getOption]);

  if (!onSearch) {
    return null;
  }

  return (
    <Autocomplete
      id={`autocomplete_${prop}`}
      value={currentValue ?? null}
      options={optionsWithValue}
      open={open && !!options}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleChangeValue}
      onInputChange={handleChangeInput}
      inputValue={inputValue}
      getOptionLabel={option => getProp(option, getLabel)}
      renderOption={renderOption}
      getOptionSelected={(option, value) => option.id === value.id}
      disabled={disabled}
      disableClearable={required}
      groupBy={groupBy}
      clearOnBlur={false}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          {...other}
          error={error || validationError || searchError}
          timestamp={timestamp}
          required={required}
          isLoading={isLoading}
          onChange={handleChangeType}
          placeholder="Digite para pesquisar"
        />
      )}
    />
  );
}

AsyncAutocompleteComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  value: PropTypes.any,
  prop: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getOption: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  setError: PropTypes.func,
  error: PropTypes.bool,
  groupBy: PropTypes.func,
  data: PropTypes.object,
  delay: PropTypes.number,
  fetchOnStart: PropTypes.bool,

  validate: PropTypes.func
};

AsyncAutocompleteComponent.defaultProps = {
  getValue: 'id',
  getLabel: 'name',
  delay: 1000
};

export default memo(AsyncAutocompleteComponent);
