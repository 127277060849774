import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton
} from '@material-ui/core';
import { Visibility as ShowIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Button } from 'components';
import { useAuthAccess, useAuth } from 'context';
import { useSnackbar } from 'notistack';

export default function AccessSelectorComponent () {
  const { acceptTerm, loading: { signOut: isLeaving } } = useAuth();
  const { accessList, storedAccessId, termsList, setAccess, isLoading } = useAuthAccess();
  const [currentTerm, setCurrentTerm] = useState({ open: false });
  const [accepted, setAccepted] = useState([]);
  const [accepting, setAccepting] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => setCurrentTerm(prev => ({ ...prev, open: false }));

  const { id: currentTermId, open, name, content } = currentTerm;
  const hasAccepted = accepted.includes(currentTermId);
  const isAccepting = accepting.includes(currentTermId);

  const handleAccept = termId => async () => {
    setAccepting(prev => prev.concat(termId));
    try {
      await acceptTerm(termId);
      setAccepted(prev => prev.concat(termId));
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
    } finally {
      setAccepting(prev => prev.filter(id => id !== termId));
    }
  };

  const handleOpen = term => () => {
    setCurrentTerm({ ...term, open: true });
  };

  const handleClickAccess = accessId => () => setAccess(accessId);

  useEffect(() => {
    if (storedAccessId && termsList?.length === accepted.length) {
      setAccess(storedAccessId);
    }
  }, [accepted.length, setAccess, storedAccessId, termsList?.length]);


  if (termsList) {
    return (
      <>
        <List>
          {termsList.map(term => {
            const { id: termId, name, description } = term;
            const isAccepted = accepted.includes(termId);
            const isDisabled = isAccepted || accepting.includes(termId);
            return (
              <ListItem key={termId}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={isAccepted}
                    disabled={isDisabled}
                    onClick={handleAccept(termId)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': `term_${termId}` }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`term_${termId}`}
                  primary={name}
                  secondary={description}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="content" onClick={handleOpen(term)}>
                    <ShowIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="terms-dialog-title"
          aria-describedby="terms-dialog-description"
        >
          <DialogTitle id="terms-dialog-title">
            {name}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="terms-dialog-description">
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={isAccepting}>
              {hasAccepted ? 'Fechar' : 'Não aceito'}
            </Button>
            {!hasAccepted && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAccept(currentTermId)}
                disabled={hasAccepted}
                loading={isAccepting}
              >
                Aceito
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <List disablePadding>
      {!accessList && (
        <ListItem>
          <ListItemText primary={<Skeleton width={150} />} />
        </ListItem>
      )}
      {accessList?.map(access => (
        <ListItem
          key={access.id}
          onClick={handleClickAccess(access.id)}
          disabled={isLeaving || isLoading}
          button
        >
          <ListItemText
            primary={access.Role?.name}
            secondary={access.PaymentSeller?.businessName}
          />
        </ListItem>
      ))}
    </List>
  );
}