import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import TextField from './TextField';
import { useAutocomplete } from 'hooks';

function AutocompleteComponent (props) {
  const { prop, options, disabled, required, groupBy } = props;
  const {
    handleChange,
    renderOption,
    getOptionLabel,
    currentValue,
    textFieldProps
  } = useAutocomplete(props);

  return (
    <Autocomplete
      id={`autocomplete_${prop}`}
      options={options ?? []}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      disabled={disabled || !options}
      disableClearable={required}
      onChange={handleChange}
      groupBy={groupBy}
      value={currentValue ?? null}
      renderInput={params => (
        <TextField
          {...params}
          {...textFieldProps}
        />
      )}
    />
  );
}

AutocompleteComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  prop: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getOption: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  setError: PropTypes.func,
  error: PropTypes.bool,
  groupBy: PropTypes.func,
  data: PropTypes.object,

  validate: PropTypes.func
};

AutocompleteComponent.defaultProps = {
  getValue: 'id',
  getLabel: 'name'
};

export default memo(AutocompleteComponent);
