import { useCallback, useMemo } from 'react';
import useResource from 'hooks/useSequelize';
import Api from 'providers/api';

const policies = {
  list: 'get:documents',
  show: 'get:document',
  create: 'post:documents',
  update: 'patch:document',
  remove: 'delete:document'
};

export default function useDocument () {
  const { resource, hasAccess } = useResource('/documents', policies, { label: 'name' });

  const getSignedUrl = useCallback(async filename => {
    const { data } = await Api.get('/documents/signedUrl', { params: { filename } });
    return data;
  }, []);

  const customMethods = useMemo(() => {
    const methods = {};

    if (hasAccess('get:documents/signed_url')) {
      methods.getSignedUrl = getSignedUrl;
    }

    return methods;
  }, [getSignedUrl, hasAccess]);

  return { ...resource, ...customMethods };
}
