import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import isNil from 'lodash/isNil';
import { CepFormat, CPFFormat, CNPJFormat, DocumentFormat, PhoneFormat, TelFormat } from './format';
import { toDecimal, isDocumentValid, isCnpjValid, isCpfValid, isEmailValid } from 'helpers';

const MIN_PHONE_LENGTH = 10;
const CEP_LENGTH = 8;

const parseValue = value => {
  if (typeof value !== 'string') {
    return value;
  }
  if (value === '') {
    return null;
  }
  return value.trimLeft().replace(/\s{2,}/g, ' ');
};

const hasError = (props, value) => {
  let { validate, required, minLength, min = 0, max, type } = props;
  if (typeof validate === 'function') {
    const error = validate(value, props.data);
    if (error) {
      return error;
    }
  }
  if (required && (isNil(value) || value === '')) {
    return 'required';
  }
  switch (type) {
    case 'number':
    case 'integer':
    case 'currency':
      if (!isNil(value) && typeof min === 'number' && value < min) {
        return 'to_low';
      }
      if (!isNil(value) && typeof max === 'number' && value > max) {
        return 'to_large';
      }
      break;
    case 'email':
      if (value && !isEmailValid(value)) {
        return 'invalid_email';
      }
      break;
    case 'cpf':
      if (value?.length && !isCpfValid(value)) {
        return 'invalid_cpf';
      }
      break;
    case 'cnpj':
      if (value?.length && !isCnpjValid(value)) {
        return 'invalid_cnpj';
      }
      break;
    case 'document':
      if (value?.length && !isDocumentValid(value)) {
        return 'invalid_document';
      }
      break;
    case 'tel':
      if (value && value?.length < MIN_PHONE_LENGTH) {
        return 'required';
      }
      break;
    case 'cep':
      if (value && value?.length !== CEP_LENGTH) {
        return 'required';
      }
    default:
      if (value && minLength && value?.length < minLength) {
        return 'to_short';
      }
      break;
  }
  return false;
};

function InputComponent (props) {
  const { onChange, prop, value, type, error, setError, data, ...other } = props;
  const [currentValue, setCurrentValue] = useState(value);
  const [timestamp, setTimestamp] = useState();
  useEffect(() => setTimestamp(new Date().getTime()), [data]);
  const _value = prop ? currentValue : value;

  useEffect(() => prop && setCurrentValue(value), [value, data, prop]);


  const handleChange = useCallback(val => {
    switch (type) {
      case 'currency':
        val = val === '0,0' ? null : Number(val.replace(/[^0-9]/g, ''));
        break;
      case 'integer':
        val = val.replace(/-/, '');
      case 'number':
        val = val.replace(/^\b0\d+/g, '0');
        val = val ? Number(val) : null;
        break;
      default:
        val = parseValue(val);
        break;
    }
    if (prop) {
      setCurrentValue(val);
      onChange(prop, val);
    } else {
      onChange(val);
    }
  }, [onChange, prop, type]);

  const validationError = hasError(props, _value);
  useEffect(() => setError && setError(prop, validationError), [prop, setError, validationError]);

  const customProps = {
    ...other,
    value: _value,
    onChange: handleChange,
    type,
    error: error || validationError,
    timestamp
  };
  delete customProps.validate;

  switch (type) {
    case 'tel':
      customProps.InputProps = {
        ...customProps.InputProps,
        inputComponent: TelFormat
      };
      break;
    case 'phone':
      customProps.type = 'tel';
      customProps.InputProps = {
        ...customProps.InputProps,
        inputComponent: PhoneFormat
      };
      break;
    case 'document':
      customProps.type = 'text';
      customProps.InputProps = {
        ...customProps.InputProps,
        inputComponent: DocumentFormat
      };
      break;
    case 'cpf':
      customProps.type = 'text';
      customProps.InputProps = {
        ...customProps.InputProps,
        inputComponent: CPFFormat
      };
      break;
    case 'cnpj':
      customProps.type = 'text';
      customProps.InputProps = {
        ...customProps.InputProps,
        inputComponent: CNPJFormat
      };
      break;
    case 'cep':
      customProps.type = 'text';
      customProps.InputProps = {
        ...customProps.InputProps,
        inputComponent: CepFormat
      };
      break;
    case 'currency':
      customProps.type = 'text';
      customProps.value = isNil(currentValue) ? '' : toDecimal(currentValue);
      customProps.prepend = 'R$';
      // não permite nenhum campo não numérico no input
      customProps.onKeyPress = evt => {
        if (evt.charCode < 48 || evt.charCode > 57) {
          evt.preventDefault();
        }
      };
      break;
    case 'integer':
      customProps.type = 'number';
      // não permite nenhum campo não numérico no input
      customProps.onKeyPress = evt => {
        if (evt.charCode < 48 || evt.charCode > 57) {
          evt.preventDefault();
        }
      };
      break;
  }

  return <TextField {...customProps} />;
}

InputComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  prop: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string,
  error: PropTypes.bool,
  setError: PropTypes.func,
  data: PropTypes.object,

  InputProps: PropTypes.object,

  min: PropTypes.number,
  max: PropTypes.number,
  minLength: PropTypes.number,
  validate: PropTypes.func,
  required: PropTypes.bool
};

export default memo(InputComponent);
