export function groupRoles (role) {
  return role?.Application?.name ?? role?.applicationId;
}

export function getRoleName (role) {
  if (!role) {
    return null;
  }
  let name = '';
  if (role.Application) {
    name += `${role.Application.name}:`;
  }
  name += role.name;
  return name;
}

export const roleInputProps = {
  label: 'Papel',
  getOption: 'name',
  getLabel: getRoleName,
  groupBy: groupRoles
};
