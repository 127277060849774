import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:utilizations',
  remove: 'delete:utilization'
};

export default function useUtilization () {
  const { resource } = useResource('/utilizations', policies);

  return resource;
}
