import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { NavigateNext as ArrowIcon } from '@material-ui/icons';
import routes from 'pages/routes';
import castArray from 'lodash/castArray';
import useStyles from './styles';

const buildUrl = params => ({ path, ...rest }) => {
  const keys = Object.keys(params);
  if (keys.length) {
    if (Array.isArray(path)) {
      path = path[0];
    }
    path = keys.reduce((p, param) => p.replace(`:${param}`, params[param]), path);
  }
  return { path, ...rest };
};

const getContent = (path, label, index, length, currentLabel, classes, state) => {
  if (index === length - 1) {
    return (
      <Typography className={classes.current}>
        {currentLabel || label}
      </Typography>
    );
  }
  const to = index === length - 2 && state?.search
    ? { pathname: path, search: state.search }
    : path;
  return (
    <>
      <Typography className={classes.text} component={Link} to={to}>
        {label}
      </Typography>
      <ArrowIcon className={classes.icon} />
    </>
  );
};

const isMatch = currentPath => ({ path }) => {
  return !!castArray(path).find(p => currentPath.indexOf(p) === 0);
};

function BreadcrumbComponent ({ match, label: currentLabel }) {
  const { state } = useLocation();
  const classes = useStyles();

  const crumbs = useMemo(() => {
    const { path, params } = match;
    return (
      routes
        .filter(isMatch(path))
        .map(buildUrl(params))
    );
  }, [match]);

  if (!match) {
    return null;
  }

  return (
    <ul className={classes.breadcrumbs}>
      {crumbs?.map(({ label, path }, index) => (
        <li key={path}>
          {getContent(path, label, index, crumbs.length, currentLabel, classes, state)}
        </li>
      ))}
    </ul>
  );
}

BreadcrumbComponent.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired
  }).isRequired,
  label: PropTypes.string
};

export default memo(withRouter(BreadcrumbComponent));
