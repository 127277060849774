import React from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExitToApp as SignOutIcon, Apps as AppsIcon } from '@material-ui/icons';
import { AuthContainer, Alert } from 'components';
import Content from './content';
import useStyles from './styles';
import { useAuthAccess, useAuth } from 'context';

const { REACT_APP_APP_URL: app_url } = process.env;

export default function AccessSelectorComponent () {
  const { accessList, errorMessage, isLoading } = useAuthAccess();
  const { currentUser, signOut, loading: { signOut: isLeaving } } = useAuth();
  const classes = useStyles();

  const disabled = isLoading || isLeaving;

  return (
    <AuthContainer title={currentUser?.Profile?.name}>
      {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : (
        !!accessList && !accessList.length && (
          <Alert severity="warning">Você não tem acesso a esta aplicação.</Alert>
        )
      )}
      <Content />
      <Divider className={classes.divider} />
      <List disablePadding>
        <ListItem component="a" href={app_url} disabled={disabled} button>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Todos os produtos" />
        </ListItem>
        <ListItem onClick={() => signOut()} disabled={disabled} button>
          <ListItemIcon>
            <SignOutIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </AuthContainer>
  );
}