import { lazy } from 'react';

const root = '/pacientes';

export const patientList = root;
export const patientUpdate = (id = ':patientId') => `${root}/${id}/editar`;

export function getPatientUpdateRoutes (patientId) {
  return [
    {
      key: 'info',
      path: patientUpdate(patientId),
      label: 'Informações',
      component: lazy(() => import('./update/Information'))
    }
  ];
};

const routes = [
  {
    key: 'patientList',
    path: patientList,
    policy: 'get:patients',
    label: 'Pacientes',
    component: lazy(() => import('./list'))
  },
  {
    key: 'patientUpdate',
    path: getPatientUpdateRoutes().map(r => r.path),
    policy: 'get:patient',
    label: 'Detalhe',
    component: lazy(() => import('./update'))
  }
];

export default routes;