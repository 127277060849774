import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:categories',
  show: 'get:category',
  create: 'post:categories',
  update: 'patch:category',
  remove: 'delete:category'
};

export default function useCategory () {
  const { resource } = useResource('/categories', policies);
  return resource;
}
