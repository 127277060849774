import React from 'react';
import { Divider } from '@material-ui/core';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const StyledDivider = styled(Divider)(compose(spacing));

export default function DividerComponent ({ ...props }) {
  return <StyledDivider {...props} />;
};
