import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Checkbox, FormControlLabel } from '@material-ui/core';
import { Button } from 'components';
import { useSnackbar } from 'notistack';

function ToggleStatusComponent ({ onToggle, value, disabled, label, className, variant, data, ...other }) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async () => {
    setIsLoading(true);
    try {
      await onToggle(!value, data);
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  switch (variant) {
    case 'button':
      return (
        <Button
          variant={value ? 'contained' : 'outlined'}
          className={className}
          {...other}
          loading={isLoading}
          disabled={disabled}
          onClick={handleChange}
        >
          {label}
        </Button>
      );
    case 'checkbox':
      return (
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              {...other}
              disabled={disabled || isLoading}
              checked={!!value}
              onChange={handleChange}
            />
          }
          label={label}
        />
      );
    default:
      return (
        <FormControlLabel
          className={className}
          control={
            <Switch
              {...other}
              disabled={disabled || isLoading}
              checked={!!value}
              onChange={handleChange}
            />
          }
          label={label}
        />
      );
  }
}

ToggleStatusComponent.propTypes = {
  onToggle: PropTypes.func.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['button', 'checkbox', 'switch']),
  data: PropTypes.any
};

export default memo(ToggleStatusComponent);