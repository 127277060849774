import React, { memo, useState, cloneElement, Children, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'clsx';
import { Typography, Accordion } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Flex } from 'components';
import CheckItem from './Item';
import useStyles, { AccordionDetails, AccordionSummary } from './styles';

export { default as CheckData } from './Data';

function CheckManagerGroupComponent ({
  data,
  children,
  itemValue,
  optionValue,
  title,
  onCreate,
  onRemove,
  selectedItems,
  disabled,
  renderTooltip
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState([]);

  const handleToggle = useCallback(id => {
    setSelected(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      }
      return prev.concat(id);
    });
  }, []);

  const content = data?.map(option => {
    const value = option[optionValue];
    const item = selectedItems?.find(item => item[itemValue] === value);
    return (
      <Flex
        className={classNames(classes.item, !selected.includes(value) && classes.empty)}
        align="center"
        key={option.id}
        spacing={1}
      >
        <CheckItem
          value={value}
          item={item}
          onCreate={onCreate}
          onRemove={onRemove}
          onChange={handleToggle}
          disabled={disabled || !selectedItems}
          renderTooltip={renderTooltip}
        />
        {Children.map(children, child => cloneElement(child, { data: option }))}
      </Flex>
    );
  });

  const checkedCount = useMemo(() => {
    return data?.filter(option => selected?.includes(option[optionValue])).length ?? 0;
  }, [data, optionValue, selected]);

  const count = useMemo(() => {
    if (!data?.length) {
      return null;
    }
    const total = data.length;
    if (checkedCount === total) {
      return <small className="textSecondary">{total}</small>;
    }

    return (
      <span>
        {checkedCount}<small className="textSecondary"> de {total}</small>
      </span>
    );
  }, [checkedCount, data?.length]);

  useEffect(() => {
    setSelected(selectedItems?.map(item => item[itemValue]));
  }, [itemValue, selectedItems]);

  if (!data) {
    return (
      <AccordionSummary>
        <Typography className={classes.groupTitle}>
          <Skeleton width={120} />
        </Typography>
      </AccordionSummary>
    );
  }

  if (title) {
    return (
      <Accordion expanded={open} onChange={() => setOpen(prev => !prev)}>
        <AccordionSummary>
          <Typography className={classes.groupTitle}>
            <span>{title}</span>
            {count}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {content}
        </AccordionDetails>
      </Accordion>
    );
  }

  return content;
};

CheckManagerGroupComponent.propTypes = {
  data: PropTypes.array,
  children: PropTypes.node,
  itemValue: PropTypes.string,
  optionValue: PropTypes.string,
  title: PropTypes.string,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
  selectedItems: PropTypes.array,
  disabled: PropTypes.bool,
  renderTooltip: PropTypes.func
};

CheckManagerGroupComponent.defaultProps = {
  optionValue: 'id'
};

export default memo(CheckManagerGroupComponent);
