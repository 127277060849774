import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  filters: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  filter: {
    '&:empty': {
      display: 'none'
    }
  },
  table: {
    minWidth: 700
  },
  paginationActions: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2)
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  }
}));
