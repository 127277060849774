import { useCallback, useMemo } from 'react';
import useResource from 'hooks/useSequelize';
import Api from 'providers/api';

const policies = {
  update: 'patch:phone',
  remove: 'delete:phone'
};

export default function usePhone () {
  const { resource, hasAccess } = useResource('/phones', policies);

  const createItem = useCallback(async (unitId, payload) => {
    if (!unitId) {
      throw new Error('unitId é obrigatório');
    }
    const { data } = await Api.post(`units/${unitId}/phones`, payload);
    return data;
  }, []);

  const customMethods = useMemo(() => {
    if (hasAccess('post:unit/phones')) {
      return { createItem };
    }
  }, [createItem, hasAccess]);

  return { ...resource, ...customMethods };
}
