import { getProp } from '.';

export function getStrippedBg (color, size, white = 'transparent', angle = 45) {
  return `repeating-linear-gradient(${angle}deg, ${color}, ${color} ${size}px, ${white} ${size}px, ${white} ${size * 2}px)`;
}

export function fadeInactive () {
  const isWrapped = typeof arguments[0] === 'string';
  const expected = (isWrapped && arguments[1]) ?? false;
  const prop = isWrapped ? arguments[0] : 'active';
  function action (item, _, theme) {
    const value = getProp(item, prop);
    if (expected === value) {
      return { color: theme.palette.text.disabled };
    }
  }
  if (isWrapped) {
    return action;
  }
  return action.apply(null, arguments);
}