export const POLICIES = {
  'get:roles': 1,
  'post:roles': 2,
  'get:role': 3,
  'patch:role': 4,
  'delete:role': 5,
  'post:role/permissions': 6,
  'get:users': 7,
  'post:users': 8,
  'get:user': 9,
  'patch:user': 10,
  'delete:user': 11,
  'patch:user/profile': 12,
  'post:user/accesses': 13,
  'get:user/accesses': 14,
  'get:policies': 15,
  'delete:permission': 16,
  'get:applications': 17,
  'get:access': 18,
  'patch:access': 19,
  'delete:access': 20,
  'get:customer': 21,
  'patch:customer/address': 22,
  'get:gateways': 23,
  'get:payment_types': 23,
  'post:partnership_gateway/reconnect': 24,
  'get:payment_sellers': 25,
  'get:payment_seller': 26,
  'post:payment_sellers': 27,
  'patch:payment_seller': 28,
  'patch:payment_seller/bank_account': 29,
  'patch:payment_seller/address': 30,
  'get:payment_seller/customers': 31,
  'post:payment_seller/customers': 32,
  'get:payment_seller/partnerships': 33,
  'post:payment_seller/payment_seller_gateways': 34,
  'post:payment_seller/partnerships': 35,
  'patch:payment_seller_gateway': 36,
  'get:accesses': 37,
  'post:users/invite': '*',
  'post:users/resend': '*',
  'get:categories': '*',
  'get:category': '*',
  'post:categories': '*',
  'patch:category': '*',
  'delete:category': '*',
  'get:feedbacks': '*',
  'get:feedback': '*',
  'patch:feedback': '*',
  'get:patients': '*',
  'get:patient': '*',
  'patch:patient': '*',
  'patch:patient/address': '*',
  'post:unit/phones': '*',
  'patch:phone': '*',
  'delete:phone': '*',
  'get:units': '*',
  'get:unit': '*',
  'post:units': '*',
  'patch:unit': '*',
  'patch:unit/address': '*',
  'get:plan_units': '*',
  'post:plan_units': '*',
  'patch:plan_unit': '*',
  'delete:plan_unit': '*',
  'get:partners': '*',
  'get:partner': '*',
  'get:product_properties': '*',
  'post:product_properties': '*',
  'patch:product_property': '*',
  'delete:product_property': '*',
  'post:partner_product_values': '*',
  'patch:partner_product_value': '*',
  'delete:partner_product_value': '*',
  'get:payment_seller_partners': '*',
  'post:payment_seller_partners': '*',
  'patch:payment_seller_partner': '*',
  'delete:payment_seller_partner': '*',
  'post:payment_seller_partner_products': '*',
  'delete:payment_seller_partner_product': '*',
  'post:payment_seller_partner_values': '*',
  'patch:payment_seller_partner_value': '*',
  'delete:payment_seller_partner_value': '*',
  'get:documents': '*',
  'get:documents/signed_url': '*',
  'get:document': '*',
  'post:documents': '*',
  'patch:document': '*',
  'delete:document': '*',
  'post:partner_product_documents': '*',
  'delete:partner_product_document': '*',
  'get:terms': '*',
  'post:terms': '*',
  'get:term': '*',
  'patch:term': '*',
  'get:user_terms': '*',
  'get:payment_seller_configurations': '*',
  'post:payment_seller_configurations': '*',
  'patch:payment_seller_configuration': '*',
  'delete:payment_seller_configuration': '*',
  'get:contracts': '*',
  'get:contract': '*',
  'get:contract/utilizations': '*',
  'get:payment_seller_fees': '*',
  'post:payment_seller_fees': '*',
  'patch:payment_seller_fee': '*',
  'delete:payment_seller_fee': '*',
  'get:gateway_logs': '*',
  'get:utilizations': '*',
  'delete:utilization': '*',
  'post:contract_patient_product/reactivate': '*',
  'post:contract_document/generate': '*',
  'get:customer': '*',
  'get:company': '*',
  'get:company': '*'
};