import useResource from 'hooks/useSequelize';
import { useCallback, useState } from 'react';

const policies = { update: 'patch:payment_seller_gateway' };

export default function usePaymentSellerGateway () {
  const { resource, request } = useResource('/paymentSellerGateways', policies);
  const [detail, setDetail] = useState();

  const invite = useCallback((paymentSellerGatewayId, email) => (
    request(`${paymentSellerGatewayId}/invite`, { method: 'post', payload: { email } })
  ), [request]);

  const togglePix = useCallback(async paymentSellerGatewayId => {
    await request(`${paymentSellerGatewayId}/togglepix`, { method: 'post' });
    setDetail(prev => {
      if (!prev?.configuration?.pix) {
        return prev;
      }
      const next = { ...prev };
      next.configuration.pix.active = !next.configuration.pix.active;
      return next;
    });
  }, [request]);

  const verify = useCallback(async paymentSellerGatewayId => {
    await request(`${paymentSellerGatewayId}/verify`, { method: 'post' });
    setDetail(prev => {
      if (prev?.['is_verified?'] !== false) {
        return prev;
      }
      const next = { ...prev };
      next['is_verified'] = true;
      return next;
    });
  }, [request]);

  const configCreditCard = useCallback(async (paymentSellerGatewayId, installments) => {
    await request(`${paymentSellerGatewayId}/configCreditCard`, {
      method: 'post',
      payload: { installments }
    });
    setDetail(prev => {
      if (!prev?.configuration?.credit_card) {
        return prev;
      }
      const next = { ...prev };
      next.configuration.credit_card.installments = installments > 0;
      next.configuration.credit_card.max_installments = String(installments);
      return next;
    });
  }, [request]);

  const getDetail = useCallback(async paymentSellerGatewayId => {
    const response = await request(`${paymentSellerGatewayId}/details`, { key: 'getDetail' });
    setDetail(response?.data);
    return response;
  }, [request]);

  return { ...resource, invite, getDetail, detail, togglePix, verify, configCreditCard };
}
