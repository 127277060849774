import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  logo: {
    height: 100
  },
  title: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(2)
  }
}));
