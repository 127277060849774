import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { TableCell, TableSortLabel } from 'components';

function ListDataSort ({ sortable, direction, active, label, onRequestSort, ...other }) {
  if (!sortable) {
    return <TableCell {...other}>{label}</TableCell>;
  }
  return (
    <TableCell {...other} sortDirection={active ? direction : false}>
      <Tooltip title="Ordenar" placement="bottom-start" enterDelay={300}>
        <TableSortLabel active={active} direction={direction} onClick={onRequestSort}>
          {label}
        </TableSortLabel>
      </Tooltip>
    </TableCell>
  );
}

ListDataSort.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  sortable: PropTypes.bool,
  direction: PropTypes.string,
  active: PropTypes.bool
};

export default memo(ListDataSort);
