import { useCallback, useMemo } from 'react';
import useResource from 'hooks/useSequelize';
import { LAST_AUTOCOMPLETE_RESULTS, AUTOCOMPLETE_RESULTS } from 'constant';

const policies = {
  list: 'get:payment_sellers',
  show: 'get:payment_seller',
  create: 'post:payment_sellers',
  update: 'patch:payment_seller'
};

export default function usePaymentSeller () {
  const { resource, request, hasAccess } = useResource('/paymentSellers', policies, { label: 'businessName' });
  const { item: paymentSeller, fetchAll } = resource;

  const fetchByName = useCallback(async (name, where) => {
    const params = name
      ? { where: { ...where, businessName: { like: name } }, order: 'businessName', direction: 'ASC', perPage: AUTOCOMPLETE_RESULTS }
      : { where, order: 'createdAt', direction: 'DESC', perPage: LAST_AUTOCOMPLETE_RESULTS };
    return await fetchAll(params);
  }, [fetchAll]);

  const addGateway = useCallback((payload, id = paymentSeller?.id) => {
    if (!id) {
      throw new Error('paymentSellerId é obrigatório');
    }
    const paymentSellerGateway = request(`${id}/paymentSellerGateways`, { method: 'post', payload });
    if (paymentSeller) {
      if (!paymentSeller?.PaymentSellerGateways) {
        paymentSeller.PaymentSellerGateways = [];
      }
      paymentSeller.PaymentSellerGateways.push(paymentSellerGateway);
    }
    return paymentSellerGateway;
  }, [paymentSeller, request]);

  const updateBankAccount = useCallback(async (payload, id = paymentSeller?.id) => {
    if (!id) {
      throw new Error('paymentSellerId é obrigatório');
    }
    const bankAccount = await request(`${id}/bankAccounts`, { method: 'patch', payload });
    if (paymentSeller) {
      paymentSeller.BankAccount = bankAccount;
    }
    return bankAccount;
  }, [paymentSeller, request]);

  const updateAddress = useCallback(async (payload, id = paymentSeller?.id) => {
    if (!id) {
      throw new Error('paymentSellerId é obrigatório');
    }
    const address = await request(`${id}/addresses`, { method: 'patch', payload });
    if (paymentSeller) {
      paymentSeller.Address = address;
    }
    return address;
  }, [paymentSeller, request]);

  const customMethods = useMemo(() => {
    const methods = {};
    if (fetchAll) {
      methods.fetchByName = fetchByName;
    }
    if (hasAccess('post:payment_seller/payment_seller_gateways')) {
      methods.addGateway = addGateway;
    }
    if (hasAccess('patch:payment_seller/bank_account')) {
      methods.updateBankAccount = updateBankAccount;
    }
    if (hasAccess('patch:payment_seller/address')) {
      methods.updateAddress = updateAddress;
    }
    return methods;
  }, [addGateway, fetchAll, fetchByName, hasAccess, updateAddress, updateBankAccount]);

  return {
    ...resource,
    ...customMethods
  };
}
