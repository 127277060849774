import castArray from 'lodash/castArray';
import isPlainObject from 'lodash/isPlainObject';

export function normalizeWhere (where) {
  const normalized = {};
  for (const key in where) {
    const value = where[key];
    if (isPlainObject(value)) {
      if (value.hasOwnProperty('like')) {
        if (value.like) {
          normalized[key] = typeof value.like === 'string' && value.like.includes('%') ? value : { like: `%${value.like}%` };
        }
      } else if (value.hasOwnProperty('gte') && value.hasOwnProperty('lte')) {
        normalized[key] = { between: [value.gte, value.lte] };
      } else {
        normalized[key] = value;
      }
    } else if (value !== '' && value !== undefined) {
      if (typeof value === 'string' && value.indexOf(',') > 0) {
        normalized[key] = { in: value.split(',').map(v => v.trim()) };
      } else {
        normalized[key] = value;
      }
    }
  }
  return normalized;
};

export function normalizeIncludes (data) {
  if (!data) {
    return;
  }
  const includes = castArray(data);
  const normalized = [];
  for (const include of includes) {
    if (typeof include === 'string') {
      normalized.push({ model: include });
    } else if (typeof include === 'object') {
      if (!include.model) {
        for (const model in include) {
          normalized.push({
            model,
            include: normalizeIncludes(include[model])
          });
        }
      } else {
        const { include: nested, where, ..._include } = include;
        if (nested) {
          _include.include = normalizeIncludes(nested);
        }
        if (where) {
          _include.where = normalizeWhere(where);
        }
        normalized.push(_include);
      }
    }
  }
  if (normalized.length) {
    return normalized;
  }
};

export function normalizeParams (params) {
  if (!isPlainObject(params)) {
    return;
  }
  const { order, direction = 'asc', where, page = 0, perPage, include } = params;
  const query = {};
  if (perPage) {
    query.limit = perPage;
    query.offset = page * perPage;
  }
  if (order) {
    query.order = [[order, direction]];
  }
  if (include) {
    query.include = normalizeIncludes(include);
  }
  if (where) {
    query.where = normalizeWhere(where);
  }
  return query;
};
