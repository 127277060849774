import { useCallback, useMemo } from 'react';
import Api from 'providers/api';
import { useAuthAccess } from 'context';

export default function usePermission (role) {
  const { hasAccess } = useAuthAccess();

  const removePermission = useCallback(async permissionId => {
    await Api.delete(`/permissions/${permissionId}`);
    if (role?.Permissions) {
      role.Permissions = role.Permissions.filter(p => p.id !== permissionId);
    }
  }, [role]);

  const addPermission = useCallback(async (policyId, roleId = role.id) => {
    const { data: permission } = await Api.post(`roles/${roleId}/permissions`, { policyId });
    if (role) {
      if (!role?.Permissions) {
        role.Permissions = [];
      }
      role.Permissions.push(permission);
    }
    return permission;
  }, [role]);

  return useMemo(() => {
    const methods = {};
    if (hasAccess('delete:permission')) {
      methods.removePermission = removePermission;
    }
    if (hasAccess('post:role/permissions')) {
      methods.addPermission = addPermission;
    }
    return methods;
  }, [addPermission, hasAccess, removePermission]);
}
