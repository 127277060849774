import React, { memo, Children } from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow } from '@material-ui/core';
import ListDataSort from './DataSort';
import { TableCell } from 'components';
import ListButtons from './Buttons';

function ListViewHeader ({ children, order, direction, onRequestSort }) {
  if (!Children.toArray(children).find(child => child?.props.label)) {
    return null;
  }
  return (
    <TableHead>
      <TableRow>
        {Children.map(children, child => {
          if (!child) {
            return null;
          }
          const { prop, sortable, align, label } = child.props;
          if (!label) {
            return <TableCell />;
          }
          if (child.type === ListButtons) {
            return <TableCell align="center">{label}</TableCell>;
          }
          const _order = typeof sortable === 'string' ? sortable : prop;
          return (
            <ListDataSort
              label={label}
              direction={direction}
              active={order === _order}
              onRequestSort={() => onRequestSort(_order)}
              sortable={!!sortable}
              align={align}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
}

ListViewHeader.propTypes = {
  children: PropTypes.node.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string,
  direction: PropTypes.string
};

export default memo(ListViewHeader);
