import { useCallback, useMemo } from 'react';
import useResource from 'hooks/useSequelize';
import { LAST_AUTOCOMPLETE_RESULTS, AUTOCOMPLETE_RESULTS } from 'constant';

const policies = {
  list: 'get:patients',
  show: 'get:patient',
  update: 'patch:patient'
};

export default function usePatient () {
  const { resource, request, setItem, hasAccess } = useResource('/patients', policies, { label: 'name' });

  const { item: patient, fetchAll } = resource;

  const fetchByName = useCallback(async (name, where) => {
    const params = name
      ? { where: { ...where, name: { like: name } }, order: 'name', direction: 'ASC', perPage: AUTOCOMPLETE_RESULTS }
      : { where, order: 'createdAt', direction: 'DESC', perPage: LAST_AUTOCOMPLETE_RESULTS };
    return await fetchAll(params);
  }, [fetchAll]);

  const updateAddress = useCallback(async (payload, id = patient?.id) => {
    if (!id) {
      throw new Error('patientId é obrigatório');
    }
    const { data: Address } = await request(`${id}/addresses`, { method: 'patch', payload });
    setItem(prev => ({ ...prev, Address }));
    return Address;
  }, [patient?.id, request, setItem]);

  const customMethods = useMemo(() => {
    const methods = {};
    if (fetchAll) {
      methods.fetchByName = fetchByName;
    }
    if (hasAccess('patch:patient/address')) {
      methods.updateAddress = updateAddress;
    }
    return methods;
  }, [fetchAll, hasAccess, fetchByName, updateAddress]);

  return { ...resource, ...customMethods };
}
