import { byId } from 'helpers/misc';

export const DOCUMENT_TYPES = [
  {
    id: 'document',
    name: 'Documento'
  },
  {
    id: 'image',
    name: 'Imagem'
  },
  {
    id: 'carne',
    name: 'Carnê'
  }
];

export const DOCUMENT_TYPES_BY_ID = byId(DOCUMENT_TYPES);
export const getDocumentTypeName = type => DOCUMENT_TYPES_BY_ID[type];
