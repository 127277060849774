import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Loader, SideMenu } from 'components';
import routes, { root, signIn } from './routes';
import menu from './menu';
import { useAuth, useAuthAccess } from 'context';

const renderRoute = ({ isPublic, user, component: Component, hasAccess, policy, ...config }) => (
  <Route exact {...config} render={props => {
    if (!isPublic && !user) {
      return <Redirect to={{ pathname: signIn, state: { from: props.location } }} />;
    }
    if (isPublic && user) {
      const to = props.location.state?.from || { pathname: root };
      return <Redirect to={to} />;
    }
    if (!hasAccess(policy)) {
      return <Redirect to={root} />;
    }
    return <Component {...props} />;
  }} />
);

export default function Pages () {
  const { currentUser: user } = useAuth();
  const { hasAccess } = useAuthAccess();
  return (
    <SideMenu data={menu}>
      <Suspense fallback={<Loader forceCenter />}>
        <Switch>
          {routes.map(route => renderRoute({ ...route, user, hasAccess }))}
        </Switch>
      </Suspense>
    </SideMenu>
  );
}
