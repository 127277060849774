import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:product_properties',
  create: 'post:product_properties',
  update: 'patch:product_property',
  remove: 'delete:product_property'
};

export default function useProductProperty () {
  const { resource } = useResource('/productProperties', policies);

  return resource;
}
