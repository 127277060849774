import React, { useCallback, useEffect, useState } from 'react';
import { getProp } from 'helpers';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const hasError = (props, value) => {
  const { validate, required } = props;
  if (typeof validate === 'function') {
    const error = validate(value);
    if (error) {
      return error;
    }
  }
  if (required && !value) {
    return 'required';
  }
  return false;
};

export default function useAutocomplete (props) {
  const {
    prop,
    value,
    data,
    error,
    onChange,
    options,
    getValue,
    getLabel,
    getOption = getLabel,
    setError,
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState(null);
  const [timestamp, setTimestamp] = useState();

  useEffect(() => setTimestamp(new Date().getTime()), [data]);

  useEffect(() => {
    const val = value && options?.find(opt => getValue ? getProp(opt, getValue) === value : opt?.id === value?.id);
    setCurrentValue(val);
  }, [options, getValue, value, data]);

  const validationError = hasError(props, currentValue);
  useEffect(
    () => setError && setError(prop, validationError),
    [prop, setError, validationError]
  );

  const handleChange = useCallback((_, val) => {
    const current = getProp(val, getValue);
    if (prop) {
      setCurrentValue(val);
      onChange(prop, current);
    } else {
      onChange(current);
    }
  }, [onChange, prop, getValue]);

  const renderOption = useCallback((option, { inputValue }) => {
    const optionValue = getProp(option, getOption);
    const matches = match(optionValue, inputValue, { insideWords: true });
    const parts = parse(optionValue, matches);

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    );
  }, [getOption]);

  const getOptionLabel = useCallback(option => getProp(option, getLabel), [getLabel]);

  delete rest.validate;
  delete rest.groupBy;

  return {
    currentValue,
    error: error || validationError,
    timestamp,
    handleChange,
    renderOption,
    getOptionLabel,
    textFieldProps: { ...rest, timestamp }
  };
}
