import React, { memo, useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import TextField from './TextField';
import { useSnackbar } from 'notistack';
import { byId, getProp } from 'helpers';
import isNil from 'lodash/isNil';

const hasError = (options, props, value) => {
  const { validate, required, getValue } = props;
  if (typeof validate === 'function') {
    const error = validate(value);
    if (error) {
      return error;
    }
  }
  if (required && (isNil(value) || value === '')) {
    return 'required';
  }
  // valida se o valor do select está na lista de opções
  if (value) {
    if (Array.isArray(value)) {
      return required && !value.length ? 'required' : false;
    }
    if (options && !options?.find((item, index) => getProp(item, getValue, index) == value)) {
      return 'invalid_option';
    }
  }
  return false;
};

function SelectComponent (props) {
  const {
    onChange,
    prop,
    value,
    options,
    isLoading: isLoadingExternal,
    getLabel,
    getValue,
    required,
    emptyLabel,
    emptyAsUndefined,
    error,
    setError,
    data,
    multiple,
    ...other
  } = props;
  const [currentValue, setCurrentValue] = useState(value);
  const [currentOptions, setCurrentOptions] = useState();
  const [optionError, setOptionError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [timestamp, setTimestamp] = useState();
  useEffect(() => setTimestamp(new Date().getTime()), [data]);

  const fetchOptions = useCallback(async () => {
    setOptionError(null);
    if (Array.isArray(options)) {
      setCurrentOptions(options);
    } else {
      setCurrentOptions();
      if (typeof options === 'function') {
        setIsLoading(true);
        try {
          setCurrentOptions(await options());
        } catch (err) {
          enqueueSnackbar(err?.message, { variant: 'error' });
          setOptionError(true);
        } finally {
          setIsLoading(false);
        }
      }
    }
  }, [enqueueSnackbar, options]);

  useEffect(() => setCurrentValue(value), [value, data]);
  useEffect(() => fetchOptions(), [fetchOptions]);

  const optionList = useMemo(() => {
    if (!Array.isArray(currentOptions)) {
      return (
        <MenuItem disabled>
          Sem opções disponíveis
        </MenuItem>
      );
    }
    const list = currentOptions.map((item, index) => {
      const val = getProp(item, getValue, index);
      return (
        <MenuItem key={val} value={val}>
          {getProp(item, getLabel)}
        </MenuItem>
      );
    });
    if ((!required || !!emptyLabel)) {
      list.unshift(
        <MenuItem key="empty" value="" disabled={required || multiple}>
          <em>{emptyLabel ?? 'Nenhum'}</em>
        </MenuItem>
      );
    }
    return list;
  }, [currentOptions, required, emptyLabel, getValue, getLabel, multiple]);

  const validationError = hasError(currentOptions, props, currentValue);
  useEffect(() => setError && setError(prop, validationError), [prop, setError, validationError]);

  const handleChange = useCallback(val => {
    const _value = val === '' ? (emptyAsUndefined ? undefined : null) : val;
    if (prop) {
      setCurrentValue(_value);
      onChange(prop, _value);
    } else {
      onChange(_value);
    }
  }, [emptyAsUndefined, onChange, prop]);

  const optionsNames = useMemo(() => byId(currentOptions, getLabel, getValue), [currentOptions, getLabel, getValue]);

  const customProps = {
    ...other,
    required,
    onChange: handleChange,
    value: currentOptions ? currentValue : '',
    error: error || validationError || optionError,
    isLoading: isLoading || isLoadingExternal,
    timestamp,
    SelectProps: {
      ...other.SelectProps,
      multiple,
      displayEmpty: !!emptyLabel
    }
  };
  delete customProps.validate;
  if (multiple) {
    if (!Array.isArray(customProps.value)) {
      customProps.value = [];
    }
    customProps.SelectProps.renderValue = selected => {
      if (selected.length === 0) {
        return <em>{emptyLabel}</em>;
      }

      return selected.map(id => optionsNames[id]).join(', ');
    };
  }
  if (emptyLabel) {
    customProps.InputLabelProps = {
      ...other.InputLabelProps,
      shrink: true
    };
  }

  return (
    <TextField {...customProps} select>
      {optionList}
    </TextField>
  );
}

SelectComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  prop: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  isLoading: PropTypes.bool,
  getLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  required: PropTypes.bool,
  emptyLabel: PropTypes.string,
  emptyAsUndefined: PropTypes.bool,
  error: PropTypes.bool,
  setError: PropTypes.func,
  data: PropTypes.object,
  multiple: PropTypes.bool,

  validate: PropTypes.func
};

SelectComponent.defaultProps = {
  getValue: 'id',
  getLabel: 'name'
};

export default memo(SelectComponent);
