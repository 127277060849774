import React, { useCallback } from 'react';
import { Skeleton } from '@material-ui/lab';
import { getProp, replaceValue } from 'helpers';

export default function useDataRequest (loading, data, defaultSize) {
  const render = useCallback((value, size) => {
    if (loading) {
      return <Skeleton width={size ?? defaultSize} />;
    }
    return replaceValue(data ? getProp(data, value) : value);
  }, [data, defaultSize, loading]);

  return render;
}