import { lazy } from 'react';

const root = '/contratos';

export const contractList = root;
export const contractDetail = (id = ':contractId') => `${root}/${id}/detalhes`;
export const contractDetailLogs = (id = ':contractId') => `${root}/${id}/logs`;
export const contractDetailUtilizations = (id = ':contractId') => `${root}/${id}/utilizacoes`;

export function getContractDetailRoutes (contractId) {
  return [
    {
      key: 'info',
      path: contractDetail(contractId),
      label: 'Informações',
      component: lazy(() => import('./detail/Information'))
    },
    {
      key: 'logs',
      path: contractDetailLogs(contractId),
      label: 'Logs',
      component: lazy(() => import('./detail/Logs'))
    },
    {
      key: 'utilizations',
      path: contractDetailUtilizations(contractId),
      policy: 'get:contract/utilizations',
      label: 'Utilizações',
      component: lazy(() => import('./detail/Utilizations'))
    }
  ];
};

const routes = [
  {
    key: 'contractList',
    path: contractList,
    policy: 'get:contracts',
    label: 'Contratos',
    component: lazy(() => import('./list'))
  },
  {
    key: 'contractDetail',
    path: getContractDetailRoutes().map(r => r.path),
    policy: 'get:contract',
    label: 'Detalhe',
    component: lazy(() => import('./detail'))
  }
];

export default routes;