import { useCallback, useState, useMemo } from 'react';
import useResource from 'hooks/useSequelize';

const policies = { list: 'get:contracts', show: 'get:contract' };

export default function useContract () {
  const [utilizations, setUtilizations] = useState();
  const { resource, request, hasAccess } = useResource('/contracts', policies, { label: 'code' });

  const fetchUtilizations = useCallback(async (contractId, query) => {
    try {
      const response = await request(`${contractId}/utilizations`, { query, key: 'utilizations' });
      setUtilizations(response);
      return response?.data;
    } catch (err) {
      setUtilizations([]);
      throw err;
    }
  }, [request]);

  const customMethods = useMemo(() => {
    if (hasAccess('get:contract/utilizations')) {
      return { fetchUtilizations };
    }
  }, [hasAccess, fetchUtilizations]);

  return { ...resource, utilizations, ...customMethods };
}
