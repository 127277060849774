import { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse, stringify } from 'qs';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isPlainObject';
import isEqual from 'lodash/isEqual';

const parseQueryValue = data => {
  if (typeof data === 'object') {
    const parsed = Array.isArray(data) ? [] : {};
    for (const key in data) {
      parsed[key] = parseQueryValue(data[key]);
    }
    return parsed;
  }
  const hasChars = new RegExp(/\D/);
  const leadingZeros = new RegExp(/^0+\d+/);
  if (data && !hasChars.test(data) && !leadingZeros.test(data)) {
    return Number(data);
  }
  return data;
};

const parseQueryString = (search, params = {}) => {
  if (!search) {
    return params;
  }
  const parsedParams = parse(search.substr(1));
  return parseQueryValue(parsedParams);
};

const removeUndefined = obj => {
  const next = {};
  let hasValue = false;
  if (isObject(obj)) {
    for (const key in obj) {
      const val = obj[key];
      const value = isObject(val) ? removeUndefined(val) : val;
      if (value !== undefined) {
        hasValue = true;
        next[key] = value;
      }
    }
  }
  if (hasValue) {
    return next;
  }
};

export default function useQueryParams (initialValue, { disabled } = {}) {
  const { search } = useLocation();
  const previousUrlParams = useRef(disabled ? initialValue : parseQueryString(search, initialValue));
  const [currentParams, setCurrentParams] = useState(cloneDeep(previousUrlParams.current));
  const history = useHistory();

  useEffect(() => {
    if (!disabled && search) {
      const params = parseQueryString(search);
      const hasChangedSearch = !isEqual(params, previousUrlParams.current);
      if (hasChangedSearch) {
        previousUrlParams.current = params;
        setCurrentParams(cloneDeep(params));
      }
    }
  }, [disabled, search]);

  const setParams = useCallback((next, replace) => {
    const nextParams = typeof next === 'function' ? next({ ...previousUrlParams.current }) : next;
    previousUrlParams.current = removeUndefined(nextParams);
    setCurrentParams(nextParams);
    history[replace ? 'replace' : 'push']({ search: stringify(nextParams) });
  }, [history]);

  return [currentParams, disabled ? setCurrentParams : setParams];
}
