import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Flex } from 'components';
import useStyles from './styles';
import { useUser } from 'models';
import { toDateAndTime, toDateDistance, byId } from 'helpers';

function ModelInfoComponent ({ data, ...props }) {
  const classes = useStyles();
  const { list: users, fetchAll: fetchUsers, isLoading: isLoadingUsers } = useUser();

  useEffect(() => {
    if (!fetchUsers || !data) {
      return;
    }
    const { createdBy, updatedBy } = data;
    const userIds = [];
    if (createdBy) {
      userIds.push(createdBy);
    }
    if (updatedBy && updatedBy !== createdBy) {
      userIds.push(updatedBy);
    }
    if (userIds.length) {
      fetchUsers({ where: { id: { in: userIds } }, include: 'Profile' });
    }
  }, [data, fetchUsers]);

  const renderUser = useMemo(() => {
    const usersByName = byId(users, 'Profile.name');
    return function render (userId) {
      if (!userId || !fetchUsers) {
        return null;
      }
      const name = usersByName[userId];
      const label = isLoadingUsers
        ? <Skeleton width={120} />
        : name ? <span className={classes.highlight}>{name}</span> : 'Desconhecido';

      return <>, <span className={classes.keep}>por: {label}</span></>;
    };
  }, [users, fetchUsers, isLoadingUsers, classes.highlight, classes.keep]);

  if (!data?.id) {
    return null;
  }

  const { createdAt, updatedAt, createdBy, updatedBy } = data;

  return (
    <Flex spacing={2} {...props}>
      {!!updatedAt && updatedAt !== createdAt && (
        <Tooltip title={toDateAndTime(updatedAt)}>
          <Typography className={classes.text}>
            Atualizado há: <span className={classes.highlight}>{toDateDistance(updatedAt)}</span>
            {renderUser(updatedBy)}
          </Typography>
        </Tooltip>
      )}
      {!!createdAt && (
        <Tooltip title={toDateAndTime(createdAt)}>
          <Typography className={classes.text}>
            Criado há: <span className={classes.highlight}>{toDateDistance(createdAt)}</span>
            {renderUser(createdBy)}
          </Typography>
        </Tooltip>
      )}
    </Flex>
  );
}

ModelInfoComponent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    createdAt: PropTypes.string,
    createdBy: PropTypes.any,
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.any
  })
};

export default memo(ModelInfoComponent);
