import axios from 'axios';
import { getToken, parseError } from '.';

const Provider = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

Provider.interceptors.request.use(async config => {
  config.headers.Authorization = getToken();
  return config;
});

Provider.interceptors.response.use(
  response => response.data,
  parseError
);

export default Provider;