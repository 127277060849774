import React from 'react';
import DynamicMaskFormat from './dynamic';

const FORMATS = [
  {
    size: 10,
    mask: '(##) ####-####'
  },
  {
    size: 11,
    mask: '(##) #####-####'
  }
];

export default function TelFormatComponent (props) {
  return <DynamicMaskFormat {...props} formats={FORMATS} />;
};
