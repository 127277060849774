import { lazy } from 'react';

const root = '/papeis';

export const roleList = root;
export const roleCreate = `${root}/adicionar`;
export const roleUpdate = (id = ':roleId') => `${root}/${id}/editar`;
export const roleUpdateAccess = (id = ':roleId') => `${root}/${id}/acessos`;

export function getRoleUpdateRoutes (roleId) {
  return [
    {
      key: 'permissions',
      path: roleUpdate(roleId),
      label: 'Informações',
      component: lazy(() => import('./update/Permissions'))
    },
    {
      key: 'accesses',
      path: roleUpdateAccess(roleId),
      policy: 'get:accesses',
      label: 'Acessos',
      component: lazy(() => import('./update/Access'))
    }
  ];
};

const routes = [
  {
    key: 'roleList',
    path: roleList,
    policy: 'get:roles',
    label: 'Papeis',
    component: lazy(() => import('./list'))
  },
  {
    key: 'roleCreate',
    path: roleCreate,
    policy: 'post:roles',
    label: 'Adicionar',
    component: lazy(() => import('./create'))
  },
  {
    key: 'roleUpdate',
    path: getRoleUpdateRoutes().map(r => r.path),
    policy: 'get:role',
    label: 'Editar',
    component: lazy(() => import('./update'))
  }
];

export default routes;