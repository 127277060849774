import { lazy } from 'react';

const root = '/acessos';

export const accessList = root;
export const accessCreate = `${root}/adicionar`;

const routes = [
  {
    key: 'accessList',
    path: accessList,
    policy: 'get:accesses',
    label: 'Acessos',
    component: lazy(() => import('./list'))
  },
  {
    key: 'accessCreate',
    path: accessCreate,
    policy: 'post:user/accesses',
    label: 'Adicionar',
    component: lazy(() => import('./create'))
  }
];

export default routes;