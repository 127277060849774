import { useCallback, useState, useMemo } from 'react';
import Api from 'providers/api';
import { useSnackbar } from 'notistack';
import { useAuthAccess } from 'context';

export default function useContractPatientProduct () {
  const { hasAccess } = useAuthAccess();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const reactivate = useCallback(async contractPatientProductId => {
    if (!contractPatientProductId) {
      throw new Error('contractPatientProductId é obrigatório');
    }
    setIsLoading(true);
    setErrorMessage(null);
    try {
      return await Api.post(`/contractPatientProducts/${contractPatientProductId}/reactivate`);
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar]);

  const customMethods = useMemo(() => {
    if (hasAccess('post:contract_patient_product/reactivate')) {
      return { reactivate };
    }
  }, [hasAccess, reactivate]);

  return {
    isLoading,
    errorMessage,
    ...customMethods
  };
}
