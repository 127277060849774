import React, { memo, useMemo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import { Loader } from 'components';
import { root } from 'pages/routes';
import useStyles from './styles';
import { useAuthAccess } from 'context';

function a11yProps (...args) {
  return {
    id: `navigation-tab-${args.join('-')}`,
    'aria-controls': `navigation-panel-${args.join('-')}`
  };
}

function TabNavigationComponent ({ id, routes, ...other }) {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const { hasAccess } = useAuthAccess();

  const route = useMemo(() => routes?.find(r => r.path === pathname), [routes, pathname]);

  if (!hasAccess(route?.policy)) {
    return <Redirect to={root} />;
  }

  const Route = route?.component;
  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={pathname}
        onChange={handleChange}
        aria-label={`navigation-tabs-${id}`}
        indicatorColor="primary"
        variant="scrollable"
      >
        {routes?.map(({ key, label, path, policy }, index) => hasAccess(policy) && (
          <Tab {...a11yProps(id, index)} value={path} label={label} key={key} />
        ))}
      </Tabs>
      <Suspense fallback={<Loader />}>
        <Route {...other} />
      </Suspense>
    </div>
  );
}

TabNavigationComponent.propTypes = {
  id: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired
};

export default memo(TabNavigationComponent);
