import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Grow from './Grow';

export { Grow };

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: ({ column }) => column ? 'column' : 'row',
    alignItems: ({ align }) => align,
    justifyContent: ({ justify }) => justify,
    flexWrap: ({ wrap }) => wrap === true ? 'wrap' : wrap,
    '& > .MuiButton-root': {
      flexShrink: 0
    },
    '& > * + *': {
      marginLeft: ({ column, spacing }) => column || !spacing ? null : theme.spacing(spacing),
      marginTop: ({ column, spacing }) => column && spacing ? theme.spacing(spacing) : null
    }
  }
}));

function FlexComponent ({
  children,
  className,
  column,
  spacing,
  align,
  justify,
  wrap,
  ...props
}) {
  const classes = useStyles({ column, spacing, align, justify, wrap });

  return (
    <Box className={classnames(classes.root, className)} {...props}>
      {children}
    </Box>
  );
}

FlexComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  column: PropTypes.bool,
  spacing: PropTypes.number,
  align: PropTypes.string,
  justify: PropTypes.string,
  wrap: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

FlexComponent.defaultProps = {
  spacing: 0
};

export default memo(FlexComponent);
