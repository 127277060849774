import useResource from 'hooks/useSequelize';

const defaultParams = { order: 'name', direction: 'asc' };
const policies = { list: 'get:applications' };

export default function useApplication () {
  const { resource } = useResource('/applications', policies, { defaultParams });

  return resource;
}
