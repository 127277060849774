import React from 'react';
import {
  dashboard,
  userList,
  roleList,
  accessList,
  unitList,
  paymentSellerList,
  categoryList,
  feedbackList,
  patientList,
  partnerList,
  termList,
  contractList,
  documentList,
  utilizationList
} from './routes';
import {
  Dashboard as DashboardIcon,
  Person as UserIcon,
  Work as RoleIcon,
  Lock as AccessIcon,
  Business as UnitIcon,
  AccountBalance as PaymentSellerIcon,
  Category as CategoryIcon,
  Feedback as FeedbackIcon,
  AssignmentInd as PatientIcon,
  GroupWork as PartnerIcon,
  Description as TermIcon,
  Receipt as ContractIcon,
  InsertDriveFile as DocumentIcon,
  Beenhere as UtilizationIcon
} from '@material-ui/icons';

const menu = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: dashboard,
    icon: <DashboardIcon />
  },
  {
    key: 'termList',
    label: 'Termos de uso',
    path: termList,
    icon: <TermIcon />
  },
  {
    key: 'userList',
    label: 'Usuários',
    path: userList,
    icon: <UserIcon />
  },
  {
    key: 'roleList',
    label: 'Papeis',
    path: roleList,
    icon: <RoleIcon />
  },
  {
    key: 'accessList',
    label: 'Acessos',
    path: accessList,
    icon: <AccessIcon />
  },
  {
    key: 'unitList',
    label: 'Unidades',
    path: unitList,
    icon: <UnitIcon />
  },
  {
    key: 'paymentSellerList',
    label: 'Grupos econômicos',
    path: paymentSellerList,
    icon: <PaymentSellerIcon />
  },
  {
    key: 'categoryList',
    label: 'Categorias',
    path: categoryList,
    icon: <CategoryIcon />
  },
  {
    key: 'feedbackList',
    label: 'Feedbacks',
    path: feedbackList,
    icon: <FeedbackIcon />
  },
  {
    key: 'patientList',
    label: 'Pacientes',
    path: patientList,
    icon: <PatientIcon />
  },
  {
    key: 'documentList',
    label: 'Documentos',
    path: documentList,
    icon: <DocumentIcon />
  },
  {
    key: 'contractList',
    label: 'Contratos',
    group: 'Planos',
    path: contractList,
    icon: <ContractIcon />
  },
  {
    key: 'utilizationList',
    label: 'Utilizações',
    group: 'Planos',
    path: utilizationList,
    icon: <UtilizationIcon />
  },
  {
    key: 'partnerList',
    label: 'Parceiros',
    group: 'Planos',
    path: partnerList,
    icon: <PartnerIcon />
  }
];

export default menu;