import { lazy } from 'react';

const root = '/termos';

export const termList = root;
export const termCreate = `${root}/adicionar`;
export const termUpdate = (id = ':termId') => `${root}/${id}/editar`;
export const termUsers = (id = ':termId') => `${root}/${id}/usuarios`;

export function getTermRoutes (termId) {
  return [
    {
      key: 'edit',
      path: termUpdate(termId),
      label: 'Informações',
      component: lazy(() => import('./update/Information'))
    },
    {
      key: 'users',
      path: termUsers(termId),
      policy: 'get:users',
      label: 'Usuários',
      component: lazy(() => import('./update/Users'))
    }
  ];
};

const routes = [
  {
    key: 'termList',
    path: termList,
    policy: 'get:terms',
    label: 'Termos de uso',
    component: lazy(() => import('./list'))
  },
  {
    key: 'termCreate',
    path: termCreate,
    policy: 'post:terms',
    label: 'Adicionar',
    component: lazy(() => import('./create'))
  },
  {
    key: 'termUpdate',
    path: getTermRoutes().map(r => r.path),
    policy: 'get:term',
    label: 'Editar',
    component: lazy(() => import('./update'))
  }
];

export default routes;