import { useCallback, useState, useMemo } from 'react';
import Api from 'providers/api';
import { useSnackbar } from 'notistack';
import { useAuthAccess } from 'context';

export default function usePartnershipGateway () {
  const { hasAccess } = useAuthAccess();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const reconnect = useCallback(async partnershipGatewayId => {
    if (!partnershipGatewayId) {
      throw new Error('partnershipGatewayId é obrigatório');
    }
    setIsLoading(true);
    setErrorMessage(null);
    try {
      return await Api.post(`/partnershipGateways/${partnershipGatewayId}/reconnect`);
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: 'error' });
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [enqueueSnackbar]);

  const customMethods = useMemo(() => {
    const methods = {};
    if (hasAccess('post:partnership_gateway/reconnect')) {
      methods.reconnect = reconnect;
    }
    return methods;
  }, [hasAccess, reconnect]);

  return {
    isLoading,
    errorMessage,
    ...customMethods
  };
}
