import { makeStyles } from '@material-ui/core/styles';
import { red, green, amber } from '@material-ui/core/colors';

const getBackgroundColor = ({ color, variant }) => {
  if (variant === 'outlined') {
    return null;
  }
  switch (color) {
    case 'success':
      return green[500];
    case 'error':
      return red[500];
    case 'warning':
      return amber[500];
    default:
      return null;
  }
};

const getHoverBackgroundColor = ({ color, variant, onClick, copy }) => {
  if (variant === 'outlined') {
    return null;
  }
  switch (color) {
    case 'success':
      return green[onClick || copy ? 800 : 500];
    case 'error':
      return red[onClick || copy ? 800 : 500];
    case 'warning':
      return amber[onClick || copy ? 800 : 500];
    default:
      return null;
  }
};

export default makeStyles(theme => ({
  root: {
    backgroundColor: getBackgroundColor,
    marginTop: ({ mt }) => typeof mt === 'number' && `${theme.spacing(mt)} !important`,
    '&:not(:only-child)': {
      marginTop: theme.spacing()
    },
    '&:hover': {
      backgroundColor: getHoverBackgroundColor
    },
    '&:focus': {
      backgroundColor: getBackgroundColor
    },
    '&:not(:last-child)': {
      marginRight: theme.spacing()
    }
  }
}));