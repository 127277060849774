import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';
import { Info as InfoIcon } from '@material-ui/icons';
import useStyles from './styles';

export default function EmptyContent ({ text, icon: Icon, paper }) {
  const classes = useStyles();
  const content = (
    <div className={classes.container}>
      {!!Icon && <Icon />}
      <Typography className={classes.text}>
        {text}
      </Typography>
    </div>
  );
  if (paper) {
    return (
      <Paper p={2}>
        {content}
      </Paper>
    );
  }
  return content;
}

EmptyContent.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.any,
  paper: PropTypes.bool
};

EmptyContent.defaultProps = {
  icon: InfoIcon
};
