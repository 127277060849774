import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:payment_seller_configurations',
  create: 'post:payment_seller_configurations',
  update: 'patch:payment_seller_configuration',
  remove: 'delete:payment_seller_configuration'
};

export default function usePaymentSellerConfiguration () {
  const { resource } = useResource('/paymentSellerConfigurations', policies);

  return resource;
}
