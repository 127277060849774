import React, { useState, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons';
import Input from './Default';

function DelayedInputComponent ({ delay, onChange, ...props }) {
  const [currentValue, setCurrentValue] = useState('');
  const didLoad = useRef(false);

  useEffect(() => {
    if (!didLoad.current) {
      didLoad.current = true;
    } else {
      if (currentValue === undefined) {
        onChange();
      } else {
        const timeoutId = setTimeout(() => {
          onChange(currentValue);
        }, delay * 1000);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [didLoad, currentValue, delay, onChange]);

  return (
    <Input
      value={currentValue || ''}
      onChange={setCurrentValue}
      prepend={<SearchIcon />}
      append={
        <IconButton disabled={!currentValue} onClick={() => setCurrentValue()}>
          <ClearIcon />
        </IconButton>
      }
      {...props}
    />
  );
}

DelayedInputComponent.propTypes = {
  delay: PropTypes.number.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func
};

DelayedInputComponent.defaultProps = {
  delay: 2
};

export default memo(DelayedInputComponent);
