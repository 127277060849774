import { makeStyles } from '@material-ui/core/styles';

const DRAWER_WIDTH = 250;

export default makeStyles(theme => ({
  content: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH
    }
  },
  menu: {
    display: 'flex',
    backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 700 : 300],
    borderRight: 'none',
    width: DRAWER_WIDTH,
    position: 'fixed',
    whiteSpace: 'nowrap'
  },
  toolbar: {
    ...theme.mixins.toolbar,
    padding: theme.spacing(0, 3),
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    height: 40
  },
  menuList: {
    overflowY: 'auto'
  },
  listIcon: {
    minWidth: 46
  },
  listItem: {
    '&.active': {
      backgroundColor: theme.palette.action.selected
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  }
}));