import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import useClipboard from 'hooks/useClipboard';

export default function CopyTextComponent ({ children, text }) {
  const classes = useStyles();
  const copy = useClipboard();

  return (
    <span
      className={classes.root}
      onClick={() => copy(text || children)}
    >
      {children}
    </span>
  );
}

CopyTextComponent.propTypes = {
  children: PropTypes.string.isRequired,
  text: PropTypes.string
};
