import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import CheckGroup from './Group';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

const Styled = styled.div(compose(spacing));

export { default as CheckData } from './Data';

function CheckManagerComponent ({
  children,
  itemValue,
  optionValue,
  onCreate,
  onRemove,
  options,
  groupBy: optionsGroupBy,
  sortBy: optionsSortBy,
  selectedItems,
  disabled,
  loading,
  renderTooltip,
  ...other
}) {
  const groupedOptions = useMemo(() => {
    if (!options) {
      return [];
    }
    if (!optionsGroupBy) {
      return [[null, options]];
    }
    const groups = {};
    for (const item of sortBy(options, optionsSortBy)) {
      const key = get(item, optionsGroupBy);
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
    }
    const _groups = [];
    for (const key in groups) {
      _groups.push([key, groups[key]]);
    }
    return sortBy(_groups, '[0]');
  }, [options, optionsGroupBy, optionsSortBy]);

  return (
    <Styled {...other}>
      {!!loading && <CheckGroup />}
      {groupedOptions?.map(([key, data]) => (
        <CheckGroup
          key={key}
          title={key}
          data={data}
          onCreate={onCreate}
          onRemove={onRemove}
          selectedItems={selectedItems}
          disabled={disabled}
          itemValue={itemValue}
          optionValue={optionValue}
          renderTooltip={renderTooltip}
        >
          {children}
        </CheckGroup>
      ))}
    </Styled>
  );
};

CheckManagerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  itemValue: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
  options: PropTypes.array,
  groupBy: PropTypes.string,
  sortBy: PropTypes.string,
  selectedItems: PropTypes.array,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  renderTooltip: PropTypes.func
};

CheckManagerComponent.defaultProps = {
  optionValue: 'id'
};

export default memo(CheckManagerComponent);
