import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:payment_seller/partnerships',
  create: 'post:payment_seller/partnerships'
};

export default function usePartnership (paymentSellerId) {
  const { resource } = useResource(`/paymentSellers/${paymentSellerId}/partnerships`, policies);

  return resource;
}
