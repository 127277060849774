export const PRODUCT_PROPERTY_TYPES = [
  {
    id: 'currency',
    name: 'Moeda'
  },
  {
    id: 'boolean',
    name: 'Contém'
  }
];

export const PRODUCT_PROPERTY_CATEGORIES = [
  {
    id: 'coverage',
    name: 'Cobertura'
  },
  {
    id: 'assistance',
    name: 'Assistência'
  },
  {
    id: 'advantage',
    name: 'Benefício'
  }
];

export const FILTER_PARTNER_STATUS = [
  {
    id: 'created',
    name: 'Criado'
  },
  {
    id: 'active',
    name: 'Ativo'
  },
  {
    id: 'disabled',
    name: 'Cancelado'
  },
  {
    id: 'error%',
    name: 'Erro'
  }
];

export const PARTNER_STATUS = {
  'created': 'Criado',
  'active': 'Ativo',
  'disabled': 'Desativado',
  'removed': 'Removido',
  'error_active': 'Erro na ativação',
  'error_disabled': 'Erro na desativação'
};

