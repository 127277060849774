import React, { useState, useCallback, useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import { getToken, setToken, clearToken } from 'providers';
import Api from 'providers/auth';

function useAuthProvider () {
  const [loading, setLoading] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [errors, setErrors] = useState({});

  const me = useCallback(async () => {
    const { data: user } = await Api.get('/me');
    setCurrentUser(user);
    return user;
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    setLoading(prev => ({ ...prev, signIn: true }));
    setErrors(prev => ({ ...prev, signIn: null }));
    try {
      const { data: { tokens } } = await Api.post('/login', { email, password });
      setToken(tokens);
      return await me();
    } catch (err) {
      setErrors(prev => ({ ...prev, signIn: err?.message }));
    } finally {
      setLoading(prev => ({ ...prev, signIn: false }));
    }
  }, [me]);

  const signOut = useCallback(async () => {
    clearToken();
    setCurrentUser(null);
  }, []);

  const resetPassword = useCallback(async email => {
    setLoading(prev => ({ ...prev, resetPassword: true }));
    setErrors(prev => ({ ...prev, resetPassword: null }));
    try {
      const { data } = await Api.post('/recovery', { email });
      return data;
    } catch (err) {
      setErrors(prev => ({ ...prev, resetPassword: err?.message }));
    }  finally {
      setLoading(prev => ({ ...prev, resetPassword: false }));
    }
  }, []);

  const acceptTerm = useCallback(async termId => {
    setLoading(prev => ({ ...prev, acceptTerm: true }));
    try {
      const { data } = await Api.post('/users/acceptTerms', { termId });
      return data;
    } finally {
      setLoading(prev => ({ ...prev, acceptTerm: false }));
    }
  }, []);

  useEffect(() => {
    const checkLogin = async () => {
      try {
        if (getToken()) {
          await me();
        } else {
          setCurrentUser(null);
        }
      } catch (err) {
        clearToken();
        setCurrentUser(null);
      }
    };
    checkLogin();
  }, [me]);

  return {
    loading,
    currentUser,
    errors,
    signIn,
    signOut,
    resetPassword,
    acceptTerm
  };
}

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export function AuthProvider ({ children, ...props }) {
  const value = useAuthProvider();
  return (
    <AuthContext.Provider {...props} value={value}>
      {value.currentUser === undefined ? <Loader forceCenter />  : children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
