import React from 'react';
import { Tooltip } from '@material-ui/core';
import { format, isSameDay, isSameYear, isValid, formatDistanceToNowStrict, parseISO } from 'date-fns';
import { locale } from 'config/date-fns';
import { fill } from '.';

function parseDate (dateString, callback) {
  if (dateString && typeof callback === 'function') {
    const date = parseISO(dateString);
    if (isValid(date)) {
      return callback(date);
    }
  }
}

export function toDate (dateString) {
  return parseDate(dateString, date => format(date, 'dd/MM/yy'));
}

export function toDateAndTime (dateString) {
  return parseDate(dateString, date => format(date, 'dd/MM/yy - HH:mm\'h\''));
}

export function toShortDate (dateString) {
  return parseDate(dateString, date => {
    const now = new Date();
    if (isSameDay(date, now)) {
      return 'Hoje';
    }
    let _format = 'd/M';
    if (!isSameYear(date, now)) {
      _format += '/yy';
    }
    return format(date, _format);
  });
}

export function toShortDateAndTime (dateString) {
  return parseDate(dateString, date => {
    const now = new Date();
    let _format = isSameDay(date, now) ? '\'Hoje\'' : 'd/M';
    if (!isSameYear(date, now)) {
      _format += '/yy';
    }
    _format += ' - ';
    if (date.getMinutes()) {
      _format += ' H:mm';
    } else {
      _format += ' H\'h\'';
    }
    return format(date, _format);
  });
}

export function toDateDistance (dateString, props) {
  return parseDate(dateString, date => (
    formatDistanceToNowStrict(date, { locale, ...props })
  ));
}

export function renderDateDistance (dateString) {
  return parseDate(dateString, date => (
    <Tooltip title={format(date, 'dd/MM/yy - HH:mm')}>
      <span>
        <small className="textSecondary">há</small>{' '}
        {formatDistanceToNowStrict(date, { locale })}
      </span>
    </Tooltip>
  ));
}

export function toAge (dateString) {
  return parseDate(dateString, date => (
    formatDistanceToNowStrict(date, { locale, roundingMethod: 'floor' })
  ));
}

export function msToTime (milliseconds) {
  const ms = milliseconds % 1000;
  milliseconds = (milliseconds - ms) / 1000;
  const secs = milliseconds % 60;
  milliseconds = (milliseconds - secs) / 60;
  const mins = milliseconds % 60;
  const hrs = (milliseconds - mins) / 60;

  const parts = [fill(mins), fill(secs)];
  if (hrs) {
    parts.shift(fill(hrs));
  }
  return parts.join(':');
}

export function toMonths (count) {
  if (count === 1) {
    return '1 mês';
  }

  if (count >= 12) {
    const years = Math.ceil(count / 12);
    const months = count % 12;
    let string = years === 1 ? '1 ano' : `${years} anos`;
    if (months) {
      string += ` e ${toMonths(months)}`;
    }
    return string;
  }

  return `${count} meses`;
}
