import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import useStyles from './styles';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';

const StyledIconButton = styled(IconButton)(compose(spacing));

const getLoaderSize = size => {
  switch (size) {
    case 'small':
      return 26;
    default:
      return 48;
  }
};

function LoadingIconButtonComponent ({ loading, disabled, children, label, placement, loaderColor, size, ...props }) {
  const loaderSize = getLoaderSize(size);
  const classes = useStyles({ size: loaderSize });
  const isDisabled = loading || disabled;
  return (
    <Tooltip placement={placement} title={isDisabled || !label ? '' : label}>
      <StyledIconButton {...props} size={size} disabled={isDisabled}>
        {children}
        {loading && (
          <CircularProgress
            size={loaderSize}
            className={classes.progress}
            color={loaderColor}
            thickness={2}
          />
        )}
      </StyledIconButton>
    </Tooltip>
  );
};

LoadingIconButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placement: PropTypes.string,
  loaderColor: PropTypes.string,
  size: PropTypes.string
};

LoadingIconButtonComponent.defaultProps = {
  placement: 'top'
};

export default memo(LoadingIconButtonComponent);
