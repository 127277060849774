import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Container, Paper, Box } from '@material-ui/core';
import { Center, Flex } from 'components';
import { NAME } from 'constant';
import useStyles from './styles';
import logo from 'assets/logo-nema_simbolo-cores_rgb_fundo_claro.png';

export default function AuthContainerComponent ({ children, ...other }) {
  const classes = useStyles();

  return (
    <Center bgcolor="primary.main" p={3} force {...other}>
      <Container component="main" maxWidth="xs">
        <Paper>
          <Box p={3}>
            <Flex column align="center">
              <img src={logo} alt={NAME} className={classes.logo} />
              <Typography component="h1" className={classes.title}>
                {NAME}
              </Typography>
            </Flex>
            <Box mt={4}>
              {children}
            </Box>
          </Box>
        </Paper>
      </Container>
    </Center>
  );
}

AuthContainerComponent.propTypes = {
  children: PropTypes.node.isRequired
};
