import { byId } from 'helpers/misc';

export const PAYMENT_TYPES = [
  {
    id: 1,
    name: 'Boleto'
  },
  {
    id: 2,
    name: 'Débito'
  },
  {
    id: 3,
    name: 'Crédito'
  }
];

export const PAYMENT_TYPES_BY_ID = byId(PAYMENT_TYPES);
export const getPaymentTypeName = paymentTypeId => PAYMENT_TYPES_BY_ID[paymentTypeId];

export const PAYMENT_STATUS = [
  {
    id: 'created',
    name: 'Criado'
  },
  {
    id: 'waiting_payment',
    name: 'Aguardando'
  },
  {
    id: 'paid',
    name: 'Pago'
  },
  {
    id: 'canceled',
    name: 'Cancelado'
  }
];

export const PAYMENT_STATUS_BY_ID = byId(PAYMENT_STATUS);
export const getPaymentStatusName = paymentStatusId => PAYMENT_STATUS_BY_ID[paymentStatusId];
