import { lazy } from 'react';

const root = '/unidades';

export const unitList = root;
export const unitCreate = `${root}/adicionar`;
export const unitUpdate = (id = ':unitId') => `${root}/${id}/editar`;
export const unitUpdatePaymentSeller = (id = ':unitId') => `${root}/${id}/ge`;

export function getUnitUpdateRoutes (unitId) {
  return [
    {
      key: 'info',
      path: unitUpdate(unitId),
      label: 'Informações',
      component: lazy(() => import('./update/Information'))
    },
    {
      key: 'paymentSeller',
      path: unitUpdatePaymentSeller(unitId),
      policy: 'get:plan_units',
      label: 'Grupos econômicos',
      component: lazy(() => import('./update/PaymentSeller'))
    }
  ];
};

const routes = [
  {
    key: 'unitList',
    path: unitList,
    policy: 'get:units',
    label: 'Unidades',
    component: lazy(() => import('./list'))
  },
  {
    key: 'unitCreate',
    path: unitCreate,
    policy: 'post:units',
    label: 'Adicionar',
    component: lazy(() => import('./create'))
  },
  {
    key: 'unitUpdate',
    path: getUnitUpdateRoutes().map(r => r.path),
    policy: 'get:unit',
    label: 'Detalhe',
    component: lazy(() => import('./update'))
  }
];

export default routes;