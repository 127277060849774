import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, Tooltip, CircularProgress } from '@material-ui/core';
import { Error, ErrorOutline } from '@material-ui/icons';
import useStyles from './styles';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';
import { useSnackbar } from 'notistack';

const StyledChip = styled(Chip)(compose(spacing));

function ChipComponent ({ loading, title, placement, onClick, onRemove, data, mt, ...props }) {
  const { color, variant, onDelete, icon } = props;

  const classes = useStyles({ color, variant, mt });
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (onRemove) {
    props.onDelete = async () => {
      setIsLoading(true);
      try {
        await onRemove(data);
      } catch (err) {
        enqueueSnackbar(err?.message, { variant: 'error' });
        setIsLoading(false);
      }
    };
  }

  if (onClick) {
    props.onClick = evt => onClick(evt, data);
  }

  switch (color) {
    case 'success':
      props.color = 'primary';
      break;
    case 'warning':
      props.color = 'secondary';
      if (icon === undefined) {
        props.icon = <ErrorOutline />;
      }
      break;
    case 'error':
      props.color = 'secondary';
      if (icon === undefined) {
        props.icon = <Error />;
      }
      break;
  }

  if (loading || isLoading) {
    if (onDelete) {
      props.deleteIcon = <CircularProgress size={22} color="secondary" />;
    } else {
      props.icon = <CircularProgress size={24} color="secondary" />;
    }
    delete props.onClick;
  }

  if (!title) {
    return <StyledChip className={classes.root} {...props} />;
  }

  return (
    <Tooltip title={title} placement={placement}>
      <StyledChip className={classes.root} {...props} />
    </Tooltip>
  );
};

ChipComponent.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  placement: PropTypes.string,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  data: PropTypes.any,
  icon: PropTypes.node,
  mt: PropTypes.number,

  color: PropTypes.string,
  variant: PropTypes.string,
  onDelete: PropTypes.func
};

ChipComponent.defaultProps = {
  placement: 'top'
};

export default memo(ChipComponent);
