import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';

function CheckboxComponent ({ onChange, prop, value, label, helperText, ...props }) {
  const [checked, setChecked] = useState(!!value);
  useEffect(() => setChecked(!!value), [value]);

  const handleChange = event => {
    const val = event.target.checked;
    if (prop) {
      setChecked(val);
      onChange(prop, val);
    } else {
      onChange(val);
    }
  };

  delete props.setError;

  return (
    <FormControl {...props}>
      <FormControlLabel
        label={label}
        checked={checked}
        control={(
          <Checkbox
            checked={checked}
            id={`checkbox_${prop}`}
            onChange={handleChange}
          />
        )}
      />
      {!!helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

CheckboxComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  prop: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  helperText: PropTypes.string,

  setError: PropTypes.func
};

export default memo(CheckboxComponent);
