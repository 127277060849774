import React from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { locale } from 'config/date-fns';
import { SnackbarProvider } from 'notistack';
import { AccessProvider, useAccess } from './access';
import { AuthProvider, useAuth } from './auth.js';
import { ConfirmationProvider, useConfirmation } from './confirmation';
import { WindowProvider, useWindow } from './window';

export { useAccess as useAuthAccess, useAuth, useConfirmation, useWindow };

export default function Context ({ children }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <SnackbarProvider hideIconVariant>
        <WindowProvider>
          <ConfirmationProvider>
            <AuthProvider>
              <AccessProvider>
                {children}
              </AccessProvider>
            </AuthProvider>
          </ConfirmationProvider>
        </WindowProvider>
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  );
}

Context.propTypes = {
  children: PropTypes.node.isRequired
};

