import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PopupForm } from 'components';
import TreeViewDrop from './Drop';
import useStyles from './styles';

function TreeView ({ children, ...props }) {
  const classes = useStyles();
  const [form, setForm] = useState({});

  const handleShowForm = useCallback((evt, data = {}, onSubmit) => {
    setForm({ anchorEl: evt.target, data, onSubmit });
  }, []);

  const handleClose = useCallback(() => setForm({}), []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className={classes.ul}>
          <TreeViewDrop {...props} onClick={handleShowForm} />
        </div>
      </DndProvider>
      {!!children && (
        <PopupForm {...form} onClose={handleClose}>
          {children}
        </PopupForm>
      )}
    </>
  );
}

TreeView.propTypes = {
  children: PropTypes.node
};

export default memo(TreeView);
