import { useMemo } from 'react';
import { useSequelize as useResource } from 'hooks';

const policies = { list: 'get:payment_seller/customers', show: 'get:customer' };

export default function useCustomer (paymentSellerId) {
  const urls = useMemo(() => ({
    list: `/paymentSellers/${paymentSellerId}/customers`,
    show: '/customers'
  }), [paymentSellerId]);

  const { resource } = useResource(urls, policies);
  return resource;
}
