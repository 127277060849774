import React, { useState, useCallback, memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { Flex } from 'components';
import useStyles from './styles';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { compose, spacing, positions } from '@material-ui/system';

const StyledButton = styled(Button)(compose(spacing, positions));
const SIZE = 24;

function AmazonUploadComponent ({
  onChange,
  getSignedUrl,
  prop,
  value,
  data,
  disabled,
  required,
  setError,
  accept,
  getFileName,
  ...props
}) {
  const classes = useStyles({ size: SIZE });
  const [progress, setProgress] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [currentValue, setCurrentValue] = useState(value);
  const _value = prop ? currentValue : value;

  useEffect(() => prop && setCurrentValue(value), [value, data, prop]);

  const handleChange = useCallback(val => {
    if (prop) {
      setCurrentValue(val);
      onChange(prop, val);
    } else {
      onChange(val);
    }
  }, [onChange, prop]);

  const handleFileChange = useCallback(
    async event => {
      const { files } = event.target;

      if (!files.length) {
        return;
      }

      setProgress(true);
      handleChange();

      const file = files[0];
      const filename = file.name;

      try {
        const { key, urlSigned } = await getSignedUrl(filename);

        await axios
          .put(urlSigned, file, {
            onUploadProgress: evt => {
              setProgress((evt.loaded / evt.total) * 100);
            }
          });
        handleChange(key);
        event.target.value = '';
      } catch (err) {
        enqueueSnackbar(err?.message, { variant: 'error' });
      } finally {
        setProgress(null);
      }
    },
    [handleChange, getSignedUrl, enqueueSnackbar]
  );

  useEffect(
    () => setError && setError(prop, required && !_value),
    [prop, required, setError, _value]
  );

  const label = _value ? 'Alterar arquivo' : 'Selecione um arquivo';
  const isDisabled = !getSignedUrl || progress !== null || disabled;

  const content = useMemo(() => getFileName && getFileName(_value, data), [_value, data, getFileName]);

  return (
    <Flex spacing={2} align="center">
      <StyledButton
        position="relative"
        variant={_value ? 'contained' : 'outlined'}
        color="secondary"
        {...props}
        component="label"
        disabled={isDisabled}
      >
        {label}
        <input
          type="file"
          className={classes.input}
          onChange={handleFileChange}
          accept={accept}
        />
        {progress !== null && (
          <CircularProgress
            size={SIZE}
            variant={progress === true ? 'indeterminate' : 'determinate'}
            value={progress !== true ? progress : null}
            className={classes.progress}
          />
        )}
      </StyledButton>
      <span>
        {content}
      </span>
    </Flex>
  );
}

AmazonUploadComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  getSignedUrl: PropTypes.func,
  prop: PropTypes.string,
  value: PropTypes.string,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  setError: PropTypes.func,
  accept: PropTypes.string,
  getFileName: PropTypes.func
};

export default memo(AmazonUploadComponent);
