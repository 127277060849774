import { useCallback, useState, useMemo } from 'react';
import useResource from 'hooks/useSequelize';
import { LAST_AUTOCOMPLETE_RESULTS, AUTOCOMPLETE_RESULTS } from 'constant';

const policies = {
  list: 'get:users',
  show: 'get:user',
  update: 'patch:user'
};

export default function useUser () {
  const { resource, request, setItem, hasAccess } = useResource('/users', policies, { label: 'Profile.name' });
  const { item: user, fetchAll } = resource;
  const [accesses, setAccesses] = useState();

  const fetchByEmail = useCallback(async email => {
    const params = email
      ? {
        where: { email: { like: `%${email}%` } },
        order: 'email',
        direction: 'ASC',
        perPage: AUTOCOMPLETE_RESULTS,
        include: 'Profile'
      }
      : {
        order: 'createdAt',
        direction: 'DESC',
        perPage: LAST_AUTOCOMPLETE_RESULTS,
        include: 'Profile'
      };
    return await fetchAll(params);
  }, [fetchAll]);

  const invite = useCallback(async payload => {
    const { data } = await request('invite', { method: 'post', payload });
    setItem(data);
    return data;
  }, [request, setItem]);

  const resend = useCallback(async id => {
    const { data } = await request(`${id}/resend`, { method: 'post', key: 'resend' });
    return data;
  }, [request]);

  const updateProfile = useCallback(async (payload, id = user?.id) => {
    if (!id) {
      throw new Error('userId é obrigatório');
    }
    const { data: Profile } = await request(`${id}/profiles`, { method: 'patch', payload });
    setItem(prev => ({ ...prev, Profile }));
    return Profile;
  }, [user?.id, request, setItem]);

  const fetchAccesses = useCallback(async (id = user?.id) => {
    if (!id) {
      return;
    }
    const response = await request(`${id}/accesses`);
    setAccesses(response);
    return response;
  }, [request, user?.id]);

  const createAccess = useCallback(async ({ Role, PaymentSeller }, id = user?.id) => {
    if (!id) {
      throw new Error('userId é obrigatório');
    }
    if (!Role?.id) {
      throw new Error('Role é obrigatório');
    }
    const { data } = await request(`${id}/accesses`, {
      method: 'post',
      payload: {
        roleId: Role.id,
        paymentSellerId: PaymentSeller?.id
      }
    });
    const access = { ...data, Role, PaymentSeller };
    setAccesses(prev => prev?.data && { data: prev.data.concat(access) });
    return access;
  }, [request, user?.id]);

  const customMethods = useMemo(() => {
    const methods = {};
    if (fetchAll) {
      methods.fetchByEmail = fetchByEmail;
    }
    if (hasAccess('post:users/invite')) {
      methods.invite = invite;
    }
    if (hasAccess('post:users/resend')) {
      methods.resend = resend;
    }
    if (hasAccess('patch:user/profile')) {
      methods.updateProfile = updateProfile;
    }
    if (hasAccess('get:user/accesses')) {
      methods.fetchAccesses = fetchAccesses;
    }
    if (hasAccess('post:user/accesses')) {
      methods.createAccess = createAccess;
    }
    return methods;
  }, [
    createAccess,
    fetchAccesses,
    fetchAll,
    fetchByEmail,
    hasAccess,
    invite,
    resend,
    updateProfile
  ]);

  return {
    ...resource,
    accesses,
    ...customMethods
  };
}
