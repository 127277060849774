import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  input: {
    display: 'none'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: ({ size }) => -size / 2,
    marginLeft: ({ size }) => -size / 2
  }
});