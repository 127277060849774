import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SwipeableDrawer, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, ListSubheader, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, NavLink } from 'components';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { NAME } from 'constant';
import { useAuth } from 'context';
import useStyles from './styles';
import logo from 'assets/logo-nema_simbolo_azul.png';

const getSubHeader = name => name !== 'undefined' ? <ListSubheader color="inherit">{name}</ListSubheader> : null;

export default function SideMenuComponent ({ data, children }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleToggleDrawer = useCallback(to => () => setOpen(prev => to ?? !!prev), []);
  const currentMenuList = useMemo(() => groupBy(data, 'group'), [data]);

  const menuContent = useMemo(() => (
    <>
      <div className={classes.menuList}>
        {map(currentMenuList, (list, name) => (
          <List key={name} subheader={getSubHeader(name)}>
            {list.map(({ key, path, label, icon }) => (
              <ListItem
                key={key}
                button
                component={NavLink}
                to={path}
                exact={path === '/'}
                onClick={handleToggleDrawer(false)}
                className={classes.listItem}
              >
                {!!icon && (
                  <ListItemIcon className={classes.listIcon}>
                    {icon}
                  </ListItemIcon>
                )}
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
        ))}
      </div>
    </>
  ), [classes, currentMenuList, handleToggleDrawer]);

  if (!currentUser) {
    return children;
  }

  return (
    <>
      <AppBar className={classes.appBar} onClick={isMobile ? handleToggleDrawer(true) : null} />
      <div id="content" className={classes.content}>
        {children}
      </div>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={handleToggleDrawer(false)}
          onOpen={handleToggleDrawer(true)}
          PaperProps={{ className: classes.menu }}
        >
          {menuContent}
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          classes={{ paper: classes.menu }}
        >
          <div className={classes.toolbar}>
            <img src={logo} alt={NAME} className={classes.logo} />
          </div>
          {menuContent}
        </Drawer>
      </Hidden>
    </>
  );
}

SideMenuComponent.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.element
    })
  )
};
