import useResource from 'hooks/useSequelize';

const policies = {
  create: 'post:payment_seller_partner_values',
  update: 'patch:payment_seller_partner_value',
  remove: 'delete:payment_seller_partner_value'
};

export default function usePaymentSellerPartnerValue () {
  const { resource } = useResource('/paymentSellerPartnerValues', policies);

  return resource;
}
