export const NAME = 'Nema Admin';
export const APPLICATION = 'Admin';

const { REACT_APP_ENVIRONMENT } = process.env;
export const NAMESPACE = `@NEMA_${REACT_APP_ENVIRONMENT}:`;

export const LAST_AUTOCOMPLETE_RESULTS = 6;
export const AUTOCOMPLETE_RESULTS = 10;

export * from './access';
export * from './banks';
export * from './bool';
export * from './document';
export * from './errors';
export * from './feedback';
export * from './gender';
export * from './payment';
export * from './plan';
export * from './sale';