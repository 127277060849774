import React, { memo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { InlineForm } from 'components';
import useStyles from './styles';
import { useConfirmation } from 'context';
import { setDataOnArray } from 'helpers';

function ResourceManagerComponent ({
  data,
  children,
  renderItem,
  onCreate,
  onUpdate,
  onRemove,
  onChangeItem,
  ...other
}) {
  const [currentItem, setCurrentItem] = useState({});
  const [currentValues, setCurrentValues] = useState([]);
  const confirm = useConfirmation();
  const classes = useStyles();

  useEffect(() => setCurrentValues(data), [data]);

  const handleSubmit = useCallback(async payload => {
    let response;
    if (currentItem.id) {
      response = await onUpdate(payload, currentItem.id);
      const index = data.findIndex(item => item.id === response.id);
      if (index >= 0) {
        data[index] = response;
      }
    } else {
      response = await onCreate(payload);
      data.push(response);
    }
    setCurrentValues(prev => setDataOnArray(prev, response));
    if (onChangeItem) {
      onChangeItem(data);
    }
    setCurrentItem({});
  }, [currentItem.id, data, onChangeItem, onCreate, onUpdate]);

  const handleCancel = useCallback(() => setCurrentItem({}), []);

  const handleSelect = useCallback(item => () => setCurrentItem(item), []);

  const handleRemove = useCallback(itemId => () => {
    confirm({
      title: 'Tem certeza de que deseja excluir?',
      size: 'xs',
      onConfirm: async () => {
        await onRemove(itemId);
        setCurrentItem(prev => prev.id === itemId ? {} : prev);
        setCurrentValues(prev => prev?.filter(item => item.id !== itemId));
        const index = data.findIndex(item => item.id === itemId);
        if (index >= 0) {
          data.splice(index, 1);
        }
        if (onChangeItem) {
          onChangeItem(data);
        }
      }
    });
    return true;
  }, [confirm, data, onChangeItem, onRemove]);

  return (
    <>
      {currentValues?.map((item, index) => (
        renderItem({
          item,
          index,
          onRemove: handleRemove(item.id),
          onSelect: handleSelect(item),
          selected: currentItem === item
        })
      ))}
      {!!currentValues?.length && (
        <Divider className={classes.divider} />
      )}
      <InlineForm
        {...other}
        data={currentItem}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        confirmLabel={currentItem.id ? 'Alterar' : 'Adicionar'}
        cancelLabel="Limpar"
        clearAfterSave
      >
        {children}
      </InlineForm>
    </>
  );
}

ResourceManagerComponent.propTypes = {
  data: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  renderItem: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onChangeItem: PropTypes.func
};

ResourceManagerComponent.defaultProps = {
  data: []
};

export default memo(ResourceManagerComponent);
