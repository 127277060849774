import { lazy } from 'react';

const root = '/usuarios';

export const userList = root;
export const userCreate = `${root}/convidar`;
export const userUpdate = (id = ':userId') => `${root}/${id}/editar`;
export const userUpdateAccess = (id = ':userId') => `${root}/${id}/acessos`;
export const userTerms = (id = ':userId') => `${root}/${id}/termos`;

export function getUserRoutes (userId) {
  return [
    {
      key: 'info',
      path: userUpdate(userId),
      label: 'Informações',
      component: lazy(() => import('./update/Information'))
    },
    {
      key: 'access',
      path: userUpdateAccess(userId),
      policy: 'get:user/accesses',
      label: 'Acessos',
      component: lazy(() => import('./update/Access'))
    },
    {
      key: 'terms',
      path: userTerms(userId),
      label: 'Termos',
      component: lazy(() => import('./update/Terms'))
    }
  ];
};

const routes = [
  {
    key: 'userList',
    path: userList,
    policy: 'get:users',
    label: 'Usuários',
    component: lazy(() => import('./list'))
  },
  {
    key: 'userCreate',
    path: userCreate,
    policy: 'post:users/invite',
    label: 'Convidar',
    component: lazy(() => import('./create'))
  },
  {
    key: 'userUpdate',
    path: getUserRoutes().map(r => r.path),
    policy: 'get:user',
    label: 'Detalhe',
    component: lazy(() => import('./update'))
  }
];

export default routes;