import React, { memo, useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Alert, DelayedInput, Button } from 'components';
import useStyles from './styles';
import { getProp } from 'helpers';

function DataPickerComponent ({
  open,
  onClose,
  onSearch,
  onSelect,
  confirmLabel,
  getLabel,
  placeholder
}) {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState();

  const fetchData = useCallback(async () => {
    if (!open || !onSearch) {
      return;
    }
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await onSearch(query);
      setData(response);
    } catch (err) {
      setErrorMessage(err?.message, { variant: 'error' });
    }
    setIsLoading(false);
  }, [onSearch, open, query]);

  useEffect(fetchData, [fetchData]);

  const handleClick = useCallback(async (item, index) => {
    setIsLoading(index);
    try {
      await onSelect(item);
      setQuery('');
    } catch (err) {
      setErrorMessage(err?.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [onSelect]);

  const content = useMemo(() => {
    if (errorMessage) {
      return (
        <Alert severity="error" m={2} square>
          {errorMessage}
        </Alert>
      );
    }
    if (!data || isLoading === true) {
      return (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton width={250} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
    if (!data.length) {
      return (
        <Alert severity="info" square>Nenhum registro encontrado</Alert>
      );
    }
    return (
      <Table size="small">
        <TableBody>
          {data?.map((item, index) => (
            <TableRow key={item.id} hover>
              <TableCell>
                {getProp(item, getLabel)}
              </TableCell>
              <TableCell align="right">
                <Button
                  onClick={handleClick.bind(null, item, index)}
                  disabled={isLoading !== false}
                  loading={isLoading === index}
                >
                  {confirmLabel}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }, [confirmLabel, data, errorMessage, getLabel, handleClick, isLoading]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.header}>
        <DelayedInput
          variant="outlined"
          disabled={isLoading !== false}
          onChange={setQuery}
          placeholder={placeholder}
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        {content}
      </DialogContent>
    </Dialog>
  );
}

DataPickerComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  getLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  placeholder: PropTypes.string
};

DataPickerComponent.defaultProps = {
  getLabel: 'name',
  confirmLabel: 'Selecionar'
};

export default memo(DataPickerComponent);
