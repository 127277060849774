import React, { useState, useCallback, useContext, createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import routes from 'pages/routes';
import { setToken, getToken } from 'providers';
import Api from 'providers/api';
import AccessSelector from './components/AccessSelector';
import { useAuth } from '.';
import castArray from 'lodash/castArray';
import jwt from 'jsonwebtoken';
import { POLICIES } from 'constant';

const PATHS = routes.reduce((paths, route) => {
  for (const p of castArray(route.path)) {
    paths.push([p, route.policy]);
  }
  return paths;
}, []);

const getPolicyByPathname = pathname => {
  const path = PATHS.find((([path]) => matchPath(pathname, { path, exact: true })));
  if (path) {
    return path[1];
  }
};

function useAccessProvider () {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [accessList, setAccessList] = useState();
  const [storedAccessId, setStoredAccessId] = useState();
  const [accessData, setAccessData] = useState();
  const [termsList, setTermsList] = useState();
  const { currentUser } = useAuth();

  const setAccess = useCallback(async accessId => {
    setStoredAccessId(accessId);
    if (!accessId) {
      return setAccessData();
    }
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const { tokens, policies } = await Api.post('/auth/accesses', { accessId });
      setToken(tokens, true);
      setAccessData({ id: accessId, policies });
    } catch (err) {
      if (err.data?.terms) {
        setTermsList(err.data.terms);
      }
      setAccessData();
      setErrorMessage(err?.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchAccesses = useCallback(async () => {
    setErrorMessage(null);
    try {
      const { data } = await Api.get('/auth/accesses');
      const { accessId } = jwt.decode(getToken());
      if (accessId && data?.find(acc => acc.id === accessId)) {
        await setAccess(accessId);
      } else if (data.length === 1) {
        await setAccess(data[0].id);
      }
      setAccessList(data);
    } catch (err) {
      setAccessList([]);
      setErrorMessage(err?.message);
    }
  }, [setAccess]);

  const currentAccess = useMemo(
    () => accessList?.find(a => a.id === accessData?.id),
    [accessData, accessList]
  );
  const isPendingAccess = !!currentUser && !currentAccess;

  useEffect(() => {
    setAccessList();
    setAccessData();
    setTermsList();
    if (currentUser) {
      fetchAccesses();
    }
  }, [currentUser, fetchAccesses]);

  const hasAccess = useCallback((policies, content) => {
    const response = content ?? true;
    if (!policies) {
      return response;
    }
    let hasAccess = false;
    for (const policy of castArray(policies)) {
      const policyId = POLICIES[policy] || policy;
      if (policyId === '*' || accessData?.policies.includes(policyId)) {
        hasAccess = true;
        break;
      }
    }
    return hasAccess ? response : ( content ? null : false );
  }, [accessData]);

  const hasAccessToPathname = useCallback(pathname => (
    hasAccess(getPolicyByPathname(pathname))
  ), [hasAccess]);

  return {
    isLoading,
    errorMessage,
    accessList,
    storedAccessId,
    currentAccess,
    isPendingAccess,
    hasAccess,
    hasAccessToPathname,
    setAccess,
    termsList
  };
}

const AccessContext = createContext();

export const useAccess = () => {
  const context = useContext(AccessContext);

  if (context === undefined) {
    throw new Error('useAccess must be used within a AccessProvider');
  }

  return context;
};

export function AccessProvider ({ children }) {
  const { isPendingAccess, ...access } = useAccessProvider();
  return (
    <AccessContext.Provider value={access}>
      {isPendingAccess ? <AccessSelector /> : children}
    </AccessContext.Provider>
  );
}

AccessProvider.propTypes = {
  children: PropTypes.node.isRequired
};
