import { byId } from 'helpers/misc';

export const BANKS = [
  {
    id: '001',
    name: '001 - Banco do Brasil',
    shortname: 'Banco do Brasil'
  },
  {
    id: '003',
    name: '003 - Banco da Amazônia S.A',
    shortname: 'Banco da Amazônia S.A'
  },
  {
    id: '004',
    name: '004 - Banco do Nordeste do Brasil',
    shortname: 'Banco do Nordeste do Brasil'
  },
  {
    id: '021',
    name: '021 - Banestes',
    shortname: 'Banestes'
  },
  {
    id: '025',
    name: '025 - Banco Alfa',
    shortname: 'Banco Alfa'
  },
  {
    id: '033',
    name: '033 - Santander',
    shortname: 'Santander'
  },
  {
    id: '037',
    name: '037 - Banco do Estado do Pará',
    shortname: 'Banco do Estado do Pará'
  },
  {
    id: '041',
    name: '041 - Banco do Estado do Rio Grande do Sul',
    shortname: 'Banco do Estado do Rio Grande do Sul'
  },
  {
    id: '047',
    name: '047 - Banco do Estado de Sergipe',
    shortname: 'Banco do Estado de Sergipe'
  },
  {
    id: '070',
    name: '070 - BRB',
    shortname: 'BRB'
  },
  {
    id: '077',
    name: '077 - Intermedium',
    shortname: 'Intermedium'
  },
  {
    id: '082',
    name: '082 - Banco Topázio',
    shortname: 'Banco Topázio'
  },
  {
    id: '084',
    name: '084 - Unicred Norte do Paraná',
    shortname: 'Unicred Norte do Paraná'
  },
  {
    id: '085',
    name: '085 - CECRED',
    shortname: 'CECRED'
  },
  {
    id: '089',
    name: '089 - Cooperativa de Crédito Rural da Região de Mogiana',
    shortname: 'Cooperativa de Crédito Rural da Região de Mogiana'
  },
  {
    id: '099',
    name: '099 - Cooperativa Central de Economia e Crédito Mutuo das Unicreds',
    shortname: 'Cooperativa Central de Economia e Crédito Mutuo das Unicreds'
  },
  {
    id: '104',
    name: '104 - Caixa Econômica',
    shortname: 'Caixa Econômica'
  },
  {
    id: '136',
    name: '136 - Confederação Nacional das Cooperativas Centrais UNICREDS',
    shortname: 'Confederação Nacional das Cooperativas Centrais UNICREDS'
  },
  {
    id: '197',
    name: '197 - Stone Pagamentos',
    shortname: 'Stone Pagamentos'
  },
  {
    id: '212',
    name: '212 - Banco Original',
    shortname: 'Banco Original'
  },
  {
    id: '213',
    name: '213 - Banco Arbi',
    shortname: 'Banco Arbi'
  },
  {
    id: '218',
    name: '218 - Banco Bonsucesso',
    shortname: 'Banco Bonsucesso'
  },
  {
    id: '224',
    name: '224 - Banco Fibra',
    shortname: 'Banco Fibra'
  },
  {
    id: '237',
    name: '237 - Banco Bradesco',
    shortname: 'Banco Bradesco'
  },
  {
    id: '246',
    name: '246 - Banco ABC Brasil',
    shortname: 'Banco ABC Brasil'
  },
  {
    id: '260',
    name: '260 - NU PAGAMENTOS S.A.',
    shortname: 'NU PAGAMENTOS S.A.'
  },
  {
    id: '290',
    name: '290 - PagSeguro Internet S.A.',
    shortname: 'PagSeguro Internet S.A.'
  },
  {
    id: '341',
    name: '341 - Itaú',
    shortname: 'Itaú'
  },
  {
    id: '376',
    name: '376 - Banco J.P. Morgan',
    shortname: 'Banco J.P. Morgan'
  },
  {
    id: '389',
    name: '389 - Banco Mercantil do Brasil',
    shortname: 'Banco Mercantil do Brasil'
  },
  {
    id: '422',
    name: '422 - Banco Safra',
    shortname: 'Banco Safra'
  },
  {
    id: '600',
    name: '600 - Banco Luso Brasileiro',
    shortname: 'Banco Luso Brasileiro'
  },
  {
    id: '604',
    name: '604 - Banco Industrial do Brasil',
    shortname: 'Banco Industrial do Brasil'
  },
  {
    id: '612',
    name: '612 - Banco Guanabara',
    shortname: 'Banco Guanabara'
  },
  {
    id: '633',
    name: '633 - Banco Rendimento',
    shortname: 'Banco Rendimento'
  },
  {
    id: '634',
    name: '634 - Banco Triângulo',
    shortname: 'Banco Triângulo'
  },
  {
    id: '637',
    name: '637 - Banco Sofisa',
    shortname: 'Banco Sofisa'
  },
  {
    id: '655',
    name: '655 - Banco Votorantim',
    shortname: 'Banco Votorantim'
  },
  {
    id: '707',
    name: '707 - Banco Daycoval',
    shortname: 'Banco Daycoval'
  },
  {
    id: '741',
    name: '741 - Banco Ribeirão Preto',
    shortname: 'Banco Ribeirão Preto'
  },
  {
    id: '743',
    name: '743 - Banco Semear',
    shortname: 'Banco Semear'
  },
  {
    id: '745',
    name: '745 - Banco Citibank',
    shortname: 'Banco Citibank'
  },
  {
    id: '748',
    name: '748 - Sicredi',
    shortname: 'Sicredi'
  },
  {
    id: '755',
    name: '755 - Bank of America Merril Lynch Banco Multiplo',
    shortname: 'Bank of America Merril Lynch Banco Multiplo'
  },
  {
    id: '756',
    name: '756 - Bancoob',
    shortname: 'Bancoob'
  },
  {
    id: '336',
    name: '336 - C6',
    shortname: 'C6'
  },
  {
    id: '403',
    name: '403 - Cora',
    shortname: 'Cora'
  }
];

export const BANKS_BY_ID = byId(BANKS);
export const getBankName = bankId => BANKS_BY_ID[bankId];
