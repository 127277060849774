import React  from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { getProp } from 'helpers';
import useStyles from './styles';

export default function CheckManagerDataComponent ({ prop, data }) {
  const classes = useStyles();
  return (
    <Typography className={classes.data} color="inherit">
      {getProp(data, prop)}
    </Typography>
  );
}

CheckManagerDataComponent.propTypes = {
  prop: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  data: PropTypes.object
};
