import { byId } from 'helpers/misc';

export const SALE_STATUS = [
  {
    id: 'created',
    name: 'Criada'
  },
  {
    id: 'pending',
    name: 'Pendente'
  },
  {
    id: 'scheduled',
    name: 'Agendada'
  },
  {
    id: 'executed',
    name: 'Realizada'
  },
  {
    id: 'cancelled',
    name: 'Cancelada'
  }
];

export const SALE_STATUS_BY_ID = byId(SALE_STATUS);
export const getSaleStatusName = statusId => SALE_STATUS_BY_ID[statusId];
