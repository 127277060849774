import { lazy } from 'react';

const root = '/categorias';

export const categoryList = root;

const routes = [
  {
    key: 'categoryList',
    path: categoryList,
    policy: 'get:categories',
    label: 'Categorias',
    component: lazy(() => import('./list'))
  }
];

export default routes;