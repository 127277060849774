import { getData, setData, clearData } from 'helpers/storage';
import { signIn } from 'pages/routes';
import { APPLICATION } from 'constant';

const NAMESPACE = 'TOKEN';
const $NAMESPACE = APPLICATION + ':' + NAMESPACE;

const getTokenByType = (data, type) => {
  const accessToken = data?.find(i => i.type === type);
  return accessToken?.token;
};

export const getToken = (type = 'token') => {
  let data;
  if (APPLICATION) {
    data = getData($NAMESPACE);
  }
  if (!data) {
    data = getData(NAMESPACE);
  }

  if (data) {
    return getTokenByType(data, type);
  }
};

export const setToken = (value, isApplicationToken) => {
  const key = APPLICATION && isApplicationToken ? $NAMESPACE : NAMESPACE;
  setData(key, value);
};

export const clearToken = () => {
  if (APPLICATION) {
    clearData($NAMESPACE);
  }
  clearData(NAMESPACE);
};

export const parseError = Error => {
  const { response, message } = Error;
  const err = { message };
  if (response) {
    const { status, data } = response;
    if (status === 401 && window.location.pathname !== signIn) {
      return window.location.assign(signIn);
    }
    const { error, ...rest } = data;
    Object.assign(err, rest);
    if (Array.isArray(error)) {
      err.message = error.join(', ');
    } else if (typeof error === 'string') {
      err.message = error;
    }
  }
  return Promise.reject(err);
};
