import React, { useEffect, useState, useCallback } from 'react';
import { Skeleton } from '@material-ui/lab';
import { getProp, byId } from 'helpers';
import uniq from 'lodash/uniq';

export default function useListRequest (listData, fetch, getValue, config = {}) {
  const { getLabel = 'name', loadingSize = 100 } = config;
  const [loadedData, setLoadedData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const loadData = useCallback(async currentData => {
    const id = uniq(currentData?.map(item => getProp(item, getValue)));
    if (id?.length) {
      setIsLoading(true);
      try {
        const responseData = await fetch({ where: { id } });
        setLoadedData(byId(responseData, getLabel));
      } finally {
        setIsLoading(false);
      }
    }
  }, [fetch, getLabel, getValue]);

  const render = useCallback(item => {
    const resourceId = String(getProp(item, getValue));
    if (resourceId) {
      if (isLoading) {
        return <Skeleton width={loadingSize} />;
      }
      return loadedData[resourceId];
    }
  }, [getValue, isLoading, loadedData, loadingSize]);

  useEffect(() => fetch && loadData(listData), [fetch, listData, loadData]);

  return render;
}