import { makeStyles, darken } from '@material-ui/core/styles';
import { grey, green } from '@material-ui/core/colors';
import { getStrippedBg } from 'helpers';

const V_SPACE = 16;
const H_SPACE = 20;
const ICON_SIZE = 20;
const defaultColor = '#E0E0E0';
const activeColor = '#333333';

export default makeStyles(theme => ({
  ul: {
    '& $li:last-child > &': {
      borderLeftWidth: 1,
      borderLeftColor: 'white'
    }
  },
  li: {
    paddingTop: V_SPACE,
    paddingLeft: H_SPACE,
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: defaultColor,
    borderLeftWidth: 1,
    '& $ul': {
      borderWidth: 0,
      borderStyle: 'solid',
      borderColor: defaultColor,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderLeftColor: 'transparent',
      marginLeft: -H_SPACE - 1,
      paddingLeft: H_SPACE + ICON_SIZE / 2
    },
    '$ul:hover > $drop > &': {
      borderLeftColor: activeColor
    },
    '&:hover': {
      '& > $item > $icon': {
        color: activeColor
      },
      '& > $ul': {
        borderTopColor: activeColor,
        borderLeftColor: defaultColor
      },
      '$ul > $drop > & ~ $li': {
        borderLeftColor: defaultColor
      }
    }
  },
  item: {
    cursor: 'pointer',
    position: 'relative',
    transform: 'translateY(50%)',
    display: 'flex',
    height: 20,
    alignItems: 'center',
    backgroundColor: 'white',
    lineHeight: 1,
    '&:hover': {
      color: activeColor,
      '& $menu': {
        visibility: 'visible'
      }
    }
  },
  button: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: ICON_SIZE,
    color: darken(defaultColor, 0.3),
    marginRight: theme.spacing()
  },
  menu: {
    paddingLeft: theme.spacing(),
    visibility: 'hidden',
    '& > * + *': {
      marginLeft: theme.spacing(0.5)
    }
  },
  hide: {
    '& $li': {
      display: 'none'
    }
  },
  drop: {
    background: ({ isOver, canDrop }) => isOver && (canDrop ? green[50] : getStrippedBg(grey[100], 4))
  },
  moveable: {
    cursor: 'move'
  }
}));
