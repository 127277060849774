import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import Api from 'providers/api';
import { setDataOnArray, normalizeIncludes, normalizeParams, getProp } from 'helpers';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import { useAuthAccess } from 'context';

/*
  policies = {
    list: 'get:resources',
    show: 'get:resource',
    create: 'post:resources',
    update: 'patch:resource',
    remove: 'delete:resource'
  }
  defaultParams = {
    order: 'string',
    direction: 'asc | desc',
    where: { prop: { op: value } },
    page: number,
    perPage: number,
    include: 'string | { include: include } | ['string' | { include: include }]'
  }
*/

export default function useResource (url, policies, config) {
  const [loading, setLoading] = useState([]);
  const [errors, setErrors] = useState({});
  const [list, setList] = useState();
  const [item, setItem] = useState();
  const { hasAccess } = useAuthAccess();
  const isMounted = useRef();

  const append = config?.append;
  const defaultParams = config?.defaultParams;
  const label = config?.label;

  const handleChangeList = useCallback(callback => {
    setList(prev => {
      if (typeof prev !== 'object') {
        return prev;
      }
      if (Array.isArray(prev)) {
        return callback(prev);
      }
      return { ...prev, data: callback(prev.data) };
    });
  }, []);

  const handleChangeItem = useCallback(data => {
    setList(prev => {
      if (!prev) {
        return prev;
      }
      if (Array.isArray(prev)) {
        return setDataOnArray(prev, data, { append });
      }
      return { ...prev, data: setDataOnArray(prev.data, data, { append }) };
    });
  }, [append]);

  const setError = useCallback((key, message) => {
    setErrors(prev => {
      if (prev[key] === message) {
        return prev;
      }
      return { ...prev, [key]: message };
    });
  }, []);

  const fetchList = useCallback(async params => {
    setLoading(prev => prev.concat('fetchList'));
    setError('fetchList', null);
    try {
      const filter = normalizeParams({ ...defaultParams, ...params });
      const _url = typeof url === 'object' ? url.list : url;
      const response = await Api.get(_url, { params: { filter } });
      if (isMounted.current) {
        const next = { ...response, params };
        setList(next);
      }
      return response;
    } catch (err) {
      if (isMounted.current) {
        setList({});
        setError('fetchList', err?.message);
      }
      throw err;
    } finally {
      if (isMounted.current) {
        setLoading(prev => prev.filter(key => key !== 'fetchList'));
      }
    }
  }, [defaultParams, setError, url]);

  const fetchAll = useCallback(async params => {
    setLoading(prev => prev.concat('fetchAll'));
    setError('fetchAll', null);
    try {
      const filter = normalizeParams({ ...defaultParams, ...params });
      const _url = typeof url === 'object' ? url.list : url;
      const { data } = await Api.get(_url, { params: { filter } });
      setList(data);
      return data;
    } catch (err) {
      setList([]);
      setError('fetchAll', err?.message);
      throw err;
    } finally {
      setLoading(prev => prev.filter(key => key !== 'fetchAll'));
    }
  }, [defaultParams, setError, url]);

  const getItem = useCallback(async (itemId, include) => {
    if (!itemId) {
      return;
    }
    setLoading(prev => prev.concat('getItem'));
    setError('getItem', null);
    try {
      const _url = typeof url === 'object' ? url.show : url;
      const config = include && {
        params: {
          filter: { include: normalizeIncludes(include) }
        }
      };
      const { data } = await Api.get(`${_url}/${itemId}`, config);
      setItem(data);
      return data;
    } catch (err) {
      setItem({});
      setError('getItem', err?.message);
      throw err;
    } finally {
      setLoading(prev => prev.filter(key => key !== 'getItem'));
    }
  }, [setError, url]);

  const createItem = useCallback(async (payload, append) => {
    setLoading(prev => prev.concat('createItem'));
    setError('createItem', null);
    try {
      const _url = typeof url === 'object' ? url.create : url;
      const { data } = await Api.post(_url, payload);
      const _item = { ...append, ...data };
      setItem(_item);
      handleChangeItem(_item);
      return _item;
    } catch (err) {
      setError('createItem', err?.message);
      throw err;
    } finally {
      setLoading(prev => prev.filter(key => key !== 'createItem'));
    }
  }, [handleChangeItem, setError, url]);

  const updateItem = useCallback(async (payload, itemId = item?.id) => {
    if (!itemId) {
      throw new Error('id obrigatório para o método update');
    }
    if (!isPlainObject(payload)) {
      throw new Error('payload obrigatório para o método update');
    }
    setLoading(prev => prev.concat('updateItem'));
    setError('updateItem', null);
    try {
      const _url = typeof url === 'object' ? url.update : url;
      const { data } = await Api.patch(`${_url}/${itemId}`, payload);
      setItem(prev => prev && ({ ...prev, ...data }));
      handleChangeItem(data);
      return data;
    } catch (err) {
      setError('updateItem', err?.message);
      throw err;
    } finally {
      setLoading(prev => prev.filter(key => key !== 'updateItem'));
    }
  }, [handleChangeItem, item?.id, setError, url]);

  const removeItem = useCallback(async (itemId = item?.id) => {
    if (!itemId) {
      throw new Error('id obrigatório para o método remove');
    }
    setLoading(prev => prev.concat('removeItem'));
    setError('removeItem', null);
    try {
      const _url = typeof url === 'object' ? url.remove : url;
      await Api.delete(`${_url}/${itemId}`);
      if (list?.params) {
        await fetchList(list?.params);
      } else if (Array.isArray(list)) {
        setList(prev => prev.filter(item => item.id !== itemId));
      }
      setItem();
      if (item?.id) {
        return true;
      }
    } catch (err) {
      setError('removeItem', err?.message);
      throw err;
    } finally {
      setLoading(prev => prev.filter(key => key !== 'removeItem'));
    }
  }, [fetchList, item?.id, list, setError, url]);

  const request = useCallback(async (path, config) => {
    const { method = 'get', payload, key, query } = typeof path === 'object' ? path : (config || {});
    if (key) {
      setLoading(prev => prev.concat(key));
      setError(key, null);
    }
    try {
      let requestUrl = url;
      if (typeof path === 'string') {
        requestUrl += `/${path}`;
      }
      const config = !payload && !isEmpty(query)
        ? { params: { filter: normalizeParams(query) } }
        : payload;

      return await Api[method](requestUrl, config);
    } catch (err) {
      if (key) {
        setError(key, err?.message);
      }
      throw err;
    } finally {
      if (key) {
        setLoading(prev => prev.filter(key => key !== key));
      }
    }
  }, [setError, url]);

  const methods = useMemo(() => {
    if (!policies) {
      return;
    }

    const {
      list: listPolicy,
      show: showPolicy,
      create: createPolicy,
      update: updatePolicy,
      remove: removePolicy
    } = policies;

    const _methods = {};

    if (listPolicy && hasAccess(listPolicy)) {
      _methods.fetchList = fetchList;
      _methods.fetchAll = fetchAll;
    }
    if (showPolicy && hasAccess(showPolicy)) {
      _methods.getItem = getItem;
    }
    if (createPolicy && hasAccess(createPolicy)) {
      _methods.createItem = createItem;
    }
    if (updatePolicy && hasAccess(updatePolicy)) {
      _methods.updateItem = updateItem;
    }
    if (removePolicy && hasAccess(removePolicy)) {
      _methods.removeItem = removeItem;
    }

    return _methods;
  }, [
    createItem,
    fetchAll,
    fetchList,
    getItem,
    hasAccess,
    policies,
    removeItem,
    updateItem
  ]);

  const itemLabel = useMemo(() => {
    if (!label) {
      return null;
    }
    if (loading.includes('getItem')) {
      return 'Carregando…';
    }
    if (loading.includes('updateItem')) {
      return 'Salvando…';
    }
    return getProp(item, label);
  }, [item, label, loading]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    resource: {
      loading,
      isLoading: !!loading.length,
      errors,
      list,
      item,
      itemLabel,
      ...methods
    },
    setList: handleChangeList,
    handleChangeItem,
    setItem,
    request,
    hasAccess
  };
}
