import React from 'react';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

function wrapCountParameter (onCall) {
  function wrappedFunction (...args) {
    return typeof args[0] === 'number'
      ? onCall.apply(null, args)
      : count => onCall.apply(null, [count].concat(args));
  }
  return wrappedFunction;
}

export const pluralize = wrapCountParameter(
  (count, singular, plural) => {
    if (isNil(count)) {
      return null;
    }
    const number = Number(count);
    if (number === 1) {
      return `1 ${singular}`;
    }
    const label = typeof plural === 'string' ? plural : `${singular}s`;
    return `${count} ${label}`;
  }
);

export const append = wrapCountParameter(
  (count, prop, fallback = null) => {
    if (isNil(count)) {
      return fallback;
    }
    return `${count}${prop}`;
  }
);

export function appendValue (value, append) {
  if (!value) {
    return <span className="textDisabled">-</span>;
  }
  return `${value}${append}`;
}

export function replaceValue (value, replace) {
  return value ?? <span className={replace ? 'textSecondary' : 'textDisabled'}>{replace ?? '-'}</span>;
};

export function toFixed (value, dp) {
  return +parseFloat(value).toFixed(dp);
}

export function fill (n, width = 2, z = '0') {
  n = String(n);
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function limitChars (count) {
  return function (string) {
    if (!count || typeof string !== 'string' || string.length <= count) {
      return string;
    }
    return `${string.substring(0, count)}…`;
  };
}

export function toDecimal (number) {
  if (typeof number !== 'number') {
    return null;
  }
  const formatter = Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2
  });
  return formatter.format(Number(number) / 100);
}

export function toCurrency (number) {
  if (typeof number !== 'number') {
    return null;
  }
  const formatter = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
  return formatter.format(Number(number) / 100);
}

export function currencyRange (data, prop = 'value') {
  if (Array.isArray(data)) {
    const sorted = sortBy(data, prop);
    const min = get(sorted[0], prop);
    const max = get(sorted.pop(), prop);
    if (min === max) {
      return toCurrency(min);
    }
    return `${toCurrency(min)} - ${toCurrency(max)}`;
  }
}

export function toPhoneNumber (phone) {
  if (typeof phone !== 'string' || phone.indexOf('-') > 0) {
    return phone;
  }
  switch (phone.length) {
    case 8:
      return phone.replace(/(\d{4})(\d{4})/, '$1-$2');
    case 9:
      return phone.replace(/(\d{5})(\d{4})/, '$1-$2');
    case 10:
      return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    case 11:
      return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    case 13:
      return phone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '$1 ($2) $3-$4');
    default:
      return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '$1 ($2) $3-$4');
  }
}

export function toPostalCode (postalCode) {
  if (typeof postalCode !== 'string' || postalCode.indexOf('-') > 0) {
    return postalCode;
  }
  return postalCode.replace(/(\d{5})(\d{3})/, '$1-$2');
}

export function toDocument (document) {
  if (typeof document !== 'string' || document.indexOf('.') > 0) {
    return document;
  }
  switch (document.length) {
    case 11:
      return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    default:
      return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
}

export function toAddress (address, singleLine) {
  if (!address?.street) {
    const { street, number, complement, neighborhood, city, state, postalCode } = address;
    let line1 = [street, number, complement, neighborhood].filter(Boolean);
    let line2 = [city, state, toPostalCode(postalCode)].filter(Boolean);

    if (!line2.length) {
      return line1.join(', ');
    }

    return [line1.join(', '), singleLine ? ' - ' : <br key="break" />, line2.join(', ')];
  }
}

export function toShortAddress (address) {
  if (address?.street) {
    const { street, number, complement } = address;
    return [street, number, complement].filter(Boolean).join(', ');
  }
}

export function toLocation (address) {
  if (address) {
    const { neighborhood, city, state } = address;
    return [neighborhood, city, state].join(' - ');
  }
};
