import { lazy } from 'react';

const root = '/utilizacoes';

export const utilizationList = root;

const routes = [
  {
    key: 'utilizationList',
    path: utilizationList,
    policy: 'get:utilizations',
    label: 'Utilizações',
    component: lazy(() => import('./list'))
  }
];

export default routes;