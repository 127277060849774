import React, { memo, Children, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { Save as SaveIcon, Clear as CancelIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { RemoveButton, FabButton, FabContainer, Checkbox, Switch, AmazonUpload } from 'components';
import Fieldset from '../Fieldset';
import DataArray from '../Array';
import { useForm } from 'hooks';
import get from 'lodash/get';
import pick from 'lodash/pick';
import styled from 'styled-components';
import { compose, spacing } from '@material-ui/system';
import useStyles from './styles';

const Styled = styled.form(compose(spacing));

const defaultChildSizes = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 };
const sizeProps = Object.keys(defaultChildSizes);

function DataFormComponent ({
  children,
  onSubmit,
  data,
  disabled,
  beforeSave,
  afterSave,
  afterRemove,
  backUrl,
  onRemove,
  removeLabelProp,
  onSuccessMessage,
  onChange,
  ...other
}) {
  const { handleSubmit, isSaveDisabled, isLoading, childrenWithValues } = useForm({
    data,
    onSubmit,
    children,
    disabled,
    beforeSave,
    afterSave,
    onSuccessMessage,
    onChange
  });
  const classes = useStyles();

  const handleRemove = useCallback(async () => {
    await onRemove(data?.id);
    afterRemove && afterRemove();
    return true;
  }, [onRemove, data?.id, afterRemove]);

  return (
    <Styled {...other} onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid className={classes.container} container spacing={3} alignItems="flex-start">
        {Children.map(childrenWithValues, child => {
          if ([DataArray, Fieldset].includes(child.type) || child.props?.fullWidth) {
            return <Grid item xs={12}>{child}</Grid>;
          }
          if ([Button, Switch, Checkbox, AmazonUpload].includes(child.type)) {
            return <Grid className={classes.button} item>{child}</Grid>;
          }
          const sizes = { ...defaultChildSizes, ...pick(child.props, sizeProps) };
          return (
            <Grid item {...sizes}>
              {child}
            </Grid>
          );
        })}
      </Grid>
      <FabContainer>
        {!!onRemove && (
          <RemoveButton
            redirect={backUrl}
            onRemove={handleRemove}
            name={get(data, removeLabelProp)}
            disabled={disabled}
          >
            <FabButton color="error" label="Excluir">
              <DeleteIcon />
            </FabButton>
          </RemoveButton>
        )}
        {!!backUrl && (
          <FabButton label="Cancelar" to={backUrl} disabled={isLoading}>
            <CancelIcon />
          </FabButton>
        )}
        {!!onSubmit && (
          <FabButton
            type="submit"
            color="success"
            label="Salvar"
            loading={isLoading}
            disabled={isSaveDisabled}
          >
            <SaveIcon />
          </FabButton>
        )}
      </FabContainer>
    </Styled>
  );
}

DataFormComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  beforeSave: PropTypes.func,
  afterSave: PropTypes.func,
  afterRemove: PropTypes.func,
  backUrl: PropTypes.string,
  onRemove: PropTypes.func,
  removeLabelProp: PropTypes.string,
  onSuccessMessage: PropTypes.string,
  onChange: PropTypes.func
};

DataFormComponent.defaultProps = {
  removeLabelProp: 'name',
  onSuccessMessage: 'Salvo com sucesso'
};

export default memo(DataFormComponent);
