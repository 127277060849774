import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  logo: {
    height: 40
  },
  grow: {
    flex: 1
  },
  icon: {
    marginRight: theme.spacing(),
    display: 'block'
  },
  userInfo: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1
  },
  userRole: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: 2,
    fontSize: theme.typography.pxToRem(14)
  }
}));