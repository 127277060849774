import { lazy } from 'react';

import access from './Access/routes';
import category from './Category/routes';
import contract from './Contract/routes';
import document from './Document/routes';
import feedback from './Feedback/routes';
import partners from './Partner/routes';
import patient from './Patient/routes';
import paymentSeller from './PaymentSeller/routes';
import role from './Role/routes';
import term from './Term/routes';
import unit from './Unit/routes';
import user from './User/routes';
import utilization from './Utilization/routes';

export * from './Access/routes';
export * from './Category/routes';
export * from './Contract/routes';
export * from './Document/routes';
export * from './Feedback/routes';
export * from './Partner/routes';
export * from './Patient/routes';
export * from './PaymentSeller/routes';
export * from './Role/routes';
export * from './Term/routes';
export * from './Unit/routes';
export * from './User/routes';
export * from './Utilization/routes';

export const dashboard = '/';
export const signIn = '/entrar';
export const forgotPassword = '/esqueci-senha';
export const root = dashboard;

const routes = [
  {
    key: 'dashboard',
    path: dashboard,
    label: 'Dashboard',
    component: lazy(() => import('./Dashboard'))
  },
  {
    key: 'signIn',
    path: signIn,
    isPublic: true,
    component: lazy(() => import('./SignIn'))
  },
  {
    key: 'forgotPassword',
    path: forgotPassword,
    isPublic: true,
    component: lazy(() => import('./ForgotPassword'))
  },
  ...access,
  ...category,
  ...contract,
  ...document,
  ...feedback,
  ...partners,
  ...patient,
  ...paymentSeller,
  ...role,
  ...term,
  ...unit,
  ...user,
  ...utilization,
  {
    key: 'notFound',
    component: lazy(() => import('./NotFound'))
  }
];

export default routes;