import useResource from 'hooks/useSequelize';

const policies = {
  list: 'get:terms',
  show: 'get:term',
  create: 'post:terms',
  update: 'patch:term'
};

export default function useTerm () {
  const { resource } = useResource('/terms', policies, { label: 'name' });

  return resource;
}
