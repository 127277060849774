import useResource from 'hooks/useSequelize';

const policies = {
  create: 'post:payment_seller_partner_products',
  remove: 'delete:payment_seller_partner_product'
};

export default function usePaymentSellerPartnerProduct () {
  const { resource } = useResource('/paymentSellerPartnerProducts', policies);

  return resource;
}
